import React from 'react';
import './slogan.css';

const SloganComponent = () => {
  return (
    <div className="slogan-section">
      <h1 className="slogan-text">Memoreyess – Die smarte Lösung für Event- und Bildverwaltung</h1>
    </div>
  );
};

export default SloganComponent;
