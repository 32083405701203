import React, { useEffect, useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useNavigate } from "react-router-dom";
import Joyride from "react-joyride";  // Importiere Joyride

function Scanner() {
  const [scanResult, setScanResult] = useState(null);
  const [manualSerialNumber, setManualSerialNumber] = useState("");
  const [manualKey, setManualKey] = useState("");
  const navigate = useNavigate();

  // State for Joyride (step-by-step tour)
  const [tour, setTour] = useState({
    run: false,  // Tour startet nicht automatisch
    steps: [
      {
        target: "#scanner-title", // Ziel den QR-Code Scanner Titel an
        content: "Dies ist der QR-Code Scanner. Scanne einen QR-Code oder lade ein Bild hoch.",
      },
      {
        target: "#reader", // QR-Code reader area
        content: "Scanne den QR-Code oder lade es hoch, um zum Event weitergeleitet zu werden.",
      },
      {
        target: ".manual-event-id",
        content: "Manuell die Event-ID eingeben, falls kein QR-Code vorhanden ist.",
      },
      {
        target: ".manual-event-key",
        content: "Hier kannst du den Schlüssel für das Event manuell eingeben.",
      },
      {
        target: ".submit-button",
        content: "Klicke hier, um zum Event weitergeleitet zu werden.",
      },
    ],
  });

  useEffect(() => {
    // Überprüfen, ob der Benutzer die Joyride-Tour bereits gesehen hat
    const hasSeenTour = localStorage.getItem("hasSeenTourScanner");

    // Wenn die Tour nicht gesehen wurde, starte sie
    if (!hasSeenTour) {
      setTour((prev) => ({ ...prev, run: true }));
    }

    const scanner = new Html5QrcodeScanner("reader", {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 5,
    });

    let isScanning = true;

    // Start rendering the scanner
    scanner.render(success, error);

    function success(result) {
      if (isScanning) {
        scanner.clear();
        setScanResult(result);
        isScanning = false;

        const { eventId, key } = extractEventIdAndKey(result);
        navigate(`/user/event/${eventId}?key=${key}`);
      }
    }

    function error(err) {
      console.warn(err);
    }

    // Funktion zum Entfernen des Info-Icons
    const removeInfoIcon = () => {
      const imgElements = document.querySelectorAll("img[alt='Info icon']");
      imgElements.forEach((img) => {
        img.remove();  // Entfernt das Info-Icon
      });
    };

    const translateTextElementsToGerman = () => {
      const buttons = document.querySelectorAll("#reader button");
      const spans = document.querySelectorAll("#reader span");
      const pTags = document.querySelectorAll("#reader p");
      const divss = document.querySelectorAll("#reader div");

      // Translate buttons
      buttons.forEach((button) => {
        button.classList.add("btn-gl");

        switch (button.innerText) {
          case "Start Scanning":
            button.innerText = "Scan starten";
            break;
          case "Stop Scanning":
            button.innerText = "Scan stoppen";
            break;
          case "Request Camera Permissions":
            button.innerText = "Berechtigung anfordern";
            break;
          case "Scan Again":
            button.innerText = "Erneut scannen";
            break;
          case "Choose Image - No image choosen":
            button.innerText = "Bild auswählen - Kein Bild ausgewählt";
            break;
          default:
            break;
        }
      });

      // Translate spans and divs
      spans.forEach((span) => {
        if (span.innerText === "Scan an Image File") {
          span.innerText = "Bilddatei scannen";
        } else if (span.innerText === "No image chosen") {
          span.innerText = "Kein Bild ausgewählt";
        } else if (span.innerText === "Scan using camera directly") {
          span.innerText = "Direkt mit der Kamera scannen";
        }
      });

      pTags.forEach((p) => {
        if (p.innerText === "Or drop an image to scan") {
          p.innerText = "Oder ziehe ein Bild, um es zu scannen";
        } 
      });

      divss.forEach((div) => {
        if (div.innerText === "Or drop an image to scan") {
          div.innerText = "Oder ziehe ein Bild, um es zu scannen";
        }
      });
    };

    const addButtonClass = () => {
      translateTextElementsToGerman(); // Übersetze Buttons, Spans und P-Elemente
      removeInfoIcon(); // Entferne das Info-Icon
    };

    addButtonClass();

    const observer = new MutationObserver(translateTextElementsToGerman);
    observer.observe(document.getElementById("reader"), {
      childList: true,
      subtree: true,
    });

    return () => {
      scanner.clear();
      observer.disconnect();
    };
  }, [navigate]);

  function extractEventIdAndKey(result) {
    const url = new URL(result);
    const eventId = url.pathname.split("/").pop();
    const key = url.searchParams.get("key");
    return { eventId, key };
  }

  function handleManualSerialNumberChange(event) {
    setManualSerialNumber(event.target.value);
  }

  function handleManualKeyChange(event) {
    setManualKey(event.target.value);
  }

  function handleManualSubmit() {
    if (manualSerialNumber && manualKey) {
      navigate(`/user/event/${manualSerialNumber}?key=${manualKey}`);
    }
  }

  // Callback für Joyride
  const handleJoyrideCallback = (data) => {
    const { status, action } = data;

    // Beende die Tour und setze im localStorage, dass die Tour gesehen wurde
    if (status === "finished" || action === "close") {
      setTour((prev) => ({ ...prev, run: false }));
      localStorage.setItem("hasSeenTourScanner", "true");
    }
  };

  return (
    <div className="">
      <Joyride
        steps={tour.steps}
        run={tour.run}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        callback={handleJoyrideCallback}
      />
      <div className="CardContainer21">
        <div className="CardContainer">
          <div className="CardContainerTitle" id="scanner-title"> {/* Eindeutige ID für den QR-Code Scanner Titel */}
            <h1>QR-Code Scanner</h1>
          </div>
          {scanResult ? (
            <div className="">
              <p>
                Erfolgreich: <a href={scanResult}>{scanResult}</a>
              </p>
            </div>
          ) : (
            <div>
              <div id="reader"></div> {/* QR-Code scanner area */}
              <p className="Text">Event-ID:</p>
              <div className="manual-event-id">
                <input
                  className="form-input"
                  type="text"
                  value={manualSerialNumber}
                  onChange={handleManualSerialNumberChange}
                />
              </div>
              <p className="">Event-Schlüssel:</p>
              <div className="manual-event-key">
                <input
                  className="form-input"
                  type="text"
                  value={manualKey}
                  onChange={handleManualKeyChange}
                />
              </div>
              <br/>
              <div className="submit-button">
                <button className="btn-gl" onClick={handleManualSubmit}>
                  Öffnen
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Scanner;
