import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify"; 

const RemoveStaff = () => {
  const [staffMembers, setStaffMembers] = useState([]);
  const [selectedStaffId, setSelectedStaffId] = useState("");
  const [events, setEvents] = useState([]); // State to store the user's events
  const [selectedEventId, setSelectedEventId] = useState(""); // State for selected event
  const { userId } = useSelector((state) => state.user); // Get user ID from Redux

  // Fetch the user's events when the component loads
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/geteventsbyuserid/${userId}`
        );
        const data = await response.json();
        if (data && data.data && data.data.events.length > 0) {
          setEvents(data.data.events);
        } else {
          toast.error("Keine Events gefunden.");
        }
      } catch (error) {
        toast.error("Fehler beim Abrufen der Events.");
      }
    };

    fetchEvents();
  }, [userId]);

  // Fetch all staff for the selected event when the event ID is set
  useEffect(() => {
    if (selectedEventId) {
      const fetchStaff = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/staff/${selectedEventId}`
          );
          const data = await response.json();

          if (Array.isArray(data)) {
            setStaffMembers(data);
          } else {
            toast.error("Nicht bekanntes Format:", data);
            setStaffMembers([]);
          }
        } catch (error) {
          toast.error("Fehler der Personal-daten :", error);
          setStaffMembers([]);
        }
      };

      fetchStaff();
    }
  }, [selectedEventId]);

  const handleResetRoleById = async (e) => {
    e.preventDefault();
    if (!selectedEventId) {
      toast.warn("Bitte ein Event auswählen.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/staff/reset-role/id/${selectedEventId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: selectedStaffId }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        toast.success(`Personal entfernt: Nutzer ID ${data.staff.user_id}`);
        setStaffMembers(staffMembers.filter((staff) => staff.user_id !== data.staff.user_id));
        setSelectedStaffId("");
      } else {
        toast.error(data.error || "Fehler beim Entfernen des Staff.");
      }
    } catch (error) {
      toast.error("Server-Fehler.");
    }
  };

  return (
    <div className="Padding3">
      <div className="CardContainer">
        <form>
          <div className="CardContainerTitle">
            <h2>Personal entfernen</h2>
          </div>
          <div>
            <p>Event auswählen:</p>
            <select
              className="form-select"
              value={selectedEventId}
              onChange={(e) => setSelectedEventId(e.target.value)}
            >
              <option value="">-- Event auswählen --</option>
              {events.map((event) => (
                <option key={event.event_id} value={event.event_id}>
                  {event.title} (ID: {event.event_id})
                </option>
              ))}
            </select>
          </div>
          <div>
            <p>Personal wählen:</p>
            <select
              className="form-select"
              value={selectedStaffId}
              onChange={(e) => setSelectedStaffId(e.target.value)}
              disabled={!staffMembers.length}
            >
              <option value="" disabled>
                Personal auswählen
              </option>
              {staffMembers.map((staff) => (
                <option key={staff.user_id} value={staff.user_id}>
                  {staff.email} (ID: {staff.user_id})
                </option>
              ))}
            </select>
            <div>
              <button
                className="btn-gl"
                onClick={handleResetRoleById}
                disabled={!selectedStaffId}
              >
                Entfernen
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RemoveStaff;
