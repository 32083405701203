import React from 'react'
import Layout from "../../../components/layout"
import AddWorker from './addworker'
import RemoveWorker from './removeworker'

const Indexworker = () => {
  return (
    <Layout>
      <div className="Top ">
        <AddWorker />
        <br />
        <div className="">
          <RemoveWorker />
        </div>
      </div>
    </Layout>
  )
}

export default Indexworker