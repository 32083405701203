import { useState } from "react";
import { onRegistration } from "../../../api/auth";
import { useNavigate, NavLink } from "react-router-dom";
import "./../signup.css";

const Register = () => {
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    number: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onEmailChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value.toLowerCase() });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Überprüfung, ob die Passwörter übereinstimmen
    if (values.password !== values.confirmPassword) {
      setError("Die Passwörter stimmen nicht überein.");
      return;
    }

    try {
      const { data } = await onRegistration(values);

      setError("");
      setSuccess(
        "Registrierung erfolgreich! Bitte bestätigen Sie Ihre E-Mail-Adresse."
      );

      // E-Mail-Adresse in localStorage speichern
      localStorage.setItem("email", values.email);
      localStorage.setItem("otpToken", data.otpToken);

      navigate("/otp-verification");
    } catch (error) {
      console.error("Fehler beim Registrieren:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        setError(error.response.data.errors[0].msg);
      } else {
        setError("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
      }
      setSuccess("");
    }
  };

  return (
    <div className="Bottom">
      <div className="CardContainer">
        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="CardContainerTitle">
            <h2>Register</h2>
          </div>

          <div className="">
            <label htmlFor="name" className="form-label">
              Name:
              <input
                onChange={(e) => onChange(e)}
                type="name"
                className="form-input"
                id="name"
                name="name"
                value={values.name}
                placeholder="Max"
                required
              />
            </label>
          </div>

          <div className="">
            <label htmlFor="lastname" className="form-label">
              Nachname:
              <input
                onChange={(e) => onChange(e)}
                type="lastname"
                className="form-input"
                id="lastname"
                name="lastname"
                value={values.lastname}
                placeholder="Mustermann"
                required
              />
            </label>
          </div>

          <div className="">
            <label htmlFor="number" className="form-label">
              Telefonnummer:
              <input
                onChange={(e) => onChange(e)}
                type="number"
                className="form-input"
                id="number"
                name="number"
                value={values.number}
                placeholder="01234 5678912"
                required
              />
            </label>
          </div>

          <div className="">
            <label htmlFor="email" className="form-label">
              E-Mail-Adresse:
              <input
                onChange={(e) => onEmailChange(e)}
                type="email"
                className="form-input"
                id="email"
                name="email"
                value={values.email}
                placeholder="User@test.com"
                required
              />
            </label>
          </div>

          <div className="">
            <label htmlFor="password" className="form-label">
              Passwort:
              <input
                onChange={(e) => onChange(e)}
                type="password"
                value={values.password}
                className="form-input"
                id="password"
                name="password"
                placeholder="Passwort"
                required
              />
            </label>
          </div>

          <div className="">
            <label htmlFor="confirmPassword" className="form-label">
              Passwort bestätigen:
              <input
                onChange={(e) => onChange(e)}
                type="password"
                value={values.confirmPassword}
                className="form-input"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Passwort bestätigen"
                required
              />
            </label>
          </div>
          <NavLink to='/otp-verification'>Du hast bereits ein Token?</NavLink>

          <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
          <div style={{ color: "green", margin: "10px 0" }}>{success}</div>
          <br />
          <button type="submit" className="btn-gl">
            Absenden
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
