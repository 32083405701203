import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import Layout from "./../../../components/layout";
import Joyride from "react-joyride"; // Importiere Joyride
import "./gallery.css";
import { toast } from "react-toastify"; 

const ImageGallery = () => {
  const { eventId } = useParams();
  const location = useLocation();
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [ setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalImages, setTotalImages] = useState(0);
  const [email, setEmail] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [emailSent, setEmailSent] = useState(false); // Zeige, ob E-Mail erfolgreich gesendet wurde
  const [runJoyride, setRunJoyride] = useState(false); // Zustand für Joyride
  const imagesPerPage = 20;

  const queryParams = new URLSearchParams(location.search);
  const key = queryParams.get("key");

  // Joyride steps definition
  const steps = [
    {
      target: ".image-gallery", // Target the gallery
      content: "Hier sehen Sie die Bildergalerie. Klicken Sie auf ein Bild, um es auszuwählen.",
    },
    {
      target: ".actions", // Target the actions (delete, email)
      content: "Hier können Sie die ausgewählten Bilder löschen oder weiter gehen, um sie per E-Mail zu versenden.",
    },
    {
      target: ".pagination", // Target pagination
      content: "Verwenden Sie die Seitennavigation, um zwischen verschiedenen Seiten zu wechseln. Wird verwendet, wenn mehr als 20 Bilder vorhanden sind.",
    },
    {
      target: ".btn-gl", // Target email button
      content: "Klicken Sie hier, um das E-Mail-Formular zu öffnen und die Bilder zu versenden.",
    },
  ];

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/images/${eventId}?key=${key}&page=${currentPage}&limit=${imagesPerPage}`
        );
        setImages(response.data.images);
        setTotalImages(parseInt(response.data.totalImages, 10));
        setLoading(false);
      } catch (err) {
        toast.error("Fehler beim Laden der Bilder.");
        setLoading(false);
      }
    };

    fetchImages();
  }, [eventId, key, currentPage]);

  // Überprüfen, ob die Joyride-Tour bereits gesehen wurde
  useEffect(() => {
    const hasSeenTour = localStorage.getItem("hasSeenTourImageGallery");

    // Wenn die Tour nicht gesehen wurde, starte sie
    if (!hasSeenTour) {
      setRunJoyride(true);
    }
  }, []);

  // Callback für Joyride
  const handleJoyrideCallback = (data) => {
    const { status, action } = data;

    // Beende die Tour, wenn der Benutzer sie abgeschlossen oder geschlossen hat
    if (status === "finished" || action === "close") {
      setRunJoyride(false);
      localStorage.setItem("hasSeenTourImageGallery", "true"); // Markiere als gesehen
    }
  };

  const handleSelectImage = (gallery_id) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(gallery_id)
        ? prevSelected.filter((id) => id !== gallery_id)
        : [...prevSelected, gallery_id]
    );
  };

  const handleDeleteImages = async () => {
    try {
      await Promise.all(
        selectedImages.map(async (gallery_id) => {
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/images/${gallery_id}`
          );
        })
      );
      setImages((prevImages) =>
        prevImages.filter((image) => !selectedImages.includes(image.gallery_id))
      );
      setSelectedImages([]);
      setTotalImages(totalImages - selectedImages.length);
    } catch (err) {
      toast.error("Fehler beim Löschen der Bilder.");
    }
  };

  const handleSendEmail = async () => {
    const remainingImageIds = images.map((image) => image.gallery_id);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/send-email`,
        {
          email,
          imageIds: remainingImageIds,
        }
      );
      toast.success("E-Mail erfolgreich gesendet.");
      setEmail("");
      setEmailSent(true); // Setze den Zustand auf true nach erfolgreichem Versand
    } catch (err) {
      toast.error("Fehler beim Senden der E-Mail.");
    }
  };

  const totalPages = Math.ceil(totalImages / imagesPerPage);

  return (
    <Layout>
      <Joyride
        steps={steps} // Definiere die Joyride-Schritte
        run={runJoyride} // Starte Joyride, wenn runJoyride true ist
        continuous
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        callback={handleJoyrideCallback}
      />

      <div className="Bottom1">
        <div className="Top Bottom1">
          <div className="image-gallery">
            {images.length === 0 ? (
              <p>Keine Bilder gefunden.</p>
            ) : (
              images.map((image) => (
                <div
                  key={image.gallery_id}
                  className={`image-item ${
                    selectedImages.includes(image.gallery_id) ? "selected" : ""
                  }`}
                  onClick={() => handleSelectImage(image.gallery_id)}
                >
                  <img
                    src={`${image.image_url}`}
                    alt={`Event Bild ${image.gallery_id}`}
                    className={selectedImages.includes(image.gallery_id) ? "highlight" : ""}
                  />
                </div>
              ))
            )}
          </div>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`page-button ${
                  index + 1 === currentPage ? "active" : ""
                }`}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <div className="actions">
            <button
              className="delete-button"
              onClick={handleDeleteImages}
              disabled={selectedImages.length === 0}
            >
              Ausgewählte Bilder löschen
            </button>
            <button
              className="btn-gl"
              onClick={() => setShowEmailInput(!showEmailInput)}
            >
              {showEmailInput ? "Schließen" : "Weiter"}
            </button>
          </div>
          {showEmailInput && (
            <div className="CardContainerTop">
              <div className="CardContainer">
                {emailSent ? (
                  <p>E-Mail wurde erfolgreich gesendet!</p>
                ) : (
                  <div className="email-input">
                    <input
                      type="email"
                      placeholder="E-Mail-Adresse eingeben"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-input"
                    />
                    <button
                      className="btn-gl2"
                      onClick={handleSendEmail}
                      disabled={!email}
                    >
                      Abschicken
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ImageGallery;
