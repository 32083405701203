import React from "react";
import Layout from "../../../components/layout";
import CreateEvent from "./createevent";

const Indexevent = () => {
  return (
    <Layout>
      <div className="Top1">
        <div className="Top Bottom1">
          <CreateEvent />
        </div>
      </div>
    </Layout>
  );
};

export default Indexevent;
