import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "./../../components/layout";

const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sessionId = searchParams.get("session_id");

  useEffect(() => {
    const confirmPayment = async () => {
      if (sessionId) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/confirm-payment`,
            { sessionId }
          );
          if (response.data.success) {
            // Weiterleitung oder Anzeige von Erfolgsmeldungen
            console.log("Payment confirmed:", response.data);
          }
        } catch (error) {
          console.error("Error confirming payment:", error);
        }
      }
    };

    confirmPayment();
  }, [sessionId]);

  return (
    <Layout>
        <section className="Top1">
        <div className="CardContainerTop">
        <div className="CardContainer" style={{ textAlign: "center", marginTop: "50px" }}>
          <div className="CardContainerTitle">
          <h1>Vielen Dank für Ihren Kauf!</h1>
          </div>
          <p>
            Ihre Zahlung war erfolgreich. Sie erhalten in Kürze eine
            Bestätigungs-E-Mail.
          </p>
          <br/>
          <button className="btn-gl" onClick={() => navigate("/home")}>
            Zurück zur Startseite
          </button>
        </div>
      </div>
        </section>
    </Layout>
  );
};

export default SuccessPage;
