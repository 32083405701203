//Signup/signup.js
import React, { useState } from "react";
import Login from "./Login/login";
import Register from "./register/register";
import "./signup.css";

const AuthContainer = () => {
  const [selectedOption, setSelectedOption] = useState("login");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="Top section Bottom">
      <div className="auth-container">
        <div className="auth-buttons">
          <button
            className={`auth-button btn-gl1 ${
              selectedOption === "login" ? "active" : ""
            }`}
            onClick={() => handleOptionChange("login")}
          >
            Login
          </button>
          <button
            className={`auth-button btn-gl2 ${
              selectedOption === "register" ? "active" : ""
            }`}
            onClick={() => handleOptionChange("register")}
          >
            Register
          </button>
        </div>

        <div className="auth-content Register">
          {selectedOption === "login" ? <Login /> : <Register />}
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
