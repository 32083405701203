import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { updateProfile } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../../components/layout";
import { toast } from "react-toastify"; 

const Profile = () => {
  const { userId, token } = useSelector((state) => state.user);
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    bday: "",
    gender: "",
    street: "",
    zipcode: "",
    city: "",
    country: "",
    number: ""
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/getuserbyid/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const data = response.data;
          setValues({
            name: data.name || "",
            lastname: data.lastname || "",
            bday: data.bday || "",
            gender: data.gender || "",
            street: data.street || "",
            zipcode: data.zipcode || "",
            city: data.city || "",
            country: data.country || "",
            number: data.number || ""
          });
        })
        .catch(error => {
          toast.error("Fehler beim Laden des Profils");
        });
    }
  }, [userId, token]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const updatedFields = Object.keys(values).reduce((acc, key) => {
      if (values[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    try {
      await updateProfile(userId, updatedFields, token);
      toast.success("Profil erfolgreich aktualisiert!");

      // Nach 5 Sekunden die Seite neu laden
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      
    } catch (error) {
      toast.error("Fehler beim Aktualisieren des Profils:", error);

      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
      }
    }
  };

  return (
    <Layout>
      <div className="Top">
        <div className="CardContainerTop">
          <div className="Bottom">
            <div className="CardContainer">
              <form onSubmit={onSubmit} className="">
                <div className="CardContainerTitle">
                  <h2>Profil aktualisieren</h2>
                </div>

                <div className="">
                  <label htmlFor="name" className="form-label">
                    Name:
                    <input
                      onChange={onChange}
                      type="text"
                      className="form-input"
                      id="name"
                      name="name"
                      value={values.name}
                      placeholder="Max"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="lastname" className="form-label">
                    Nachname:
                    <input
                      onChange={onChange}
                      type="text"
                      className="form-input"
                      id="lastname"
                      name="lastname"
                      value={values.lastname}
                      placeholder="Mustermann"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="bday" className="form-label">
                    Geburtsdatum:
                    <input
                      onChange={onChange}
                      type="date"
                      className="form-input"
                      id="bday"
                      name="bday"
                      value={values.bday}
                      required
                    />
                  </label>
                </div>

                <div className="Geschlecht">
                  <label className="form-label">
                    Geschlecht:
                    <br />
                    <div className="G-Radio">
                      <label htmlFor="male">
                        Mann
                        <input
                          type="radio"
                          id="male"
                          name="gender"
                          value="M"
                          checked={values.gender === "M"}
                          onChange={onChange}
                        />
                      </label>
                    </div>
                    <div className="G-Radio">
                      <label htmlFor="female">
                        Frau
                        <input
                          type="radio"
                          id="female"
                          name="gender"
                          value="F"
                          checked={values.gender === "F"}
                          onChange={onChange}
                        />
                      </label>
                    </div>
                    <div className="G-Radio">
                      <label htmlFor="divers">
                        Divers
                        <input
                          type="radio"
                          id="divers"
                          name="gender"
                          value="D"
                          checked={values.gender === "D"}
                          onChange={onChange}
                        />
                      </label>
                    </div>
                  </label>
                </div>

                <div className="">
                  <label htmlFor="street" className="form-label">
                    Straße:
                    <input
                      onChange={onChange}
                      type="text"
                      className="form-input"
                      id="street"
                      name="street"
                      value={values.street}
                      placeholder="Musterstraße 123"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="zipcode" className="form-label">
                    Postleitzahl:
                    <input
                      onChange={onChange}
                      type="text"
                      className="form-input"
                      id="zipcode"
                      name="zipcode"
                      value={values.zipcode}
                      placeholder="12345"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="city" className="form-label">
                    Stadt:
                    <input
                      onChange={onChange}
                      type="text"
                      className="form-input"
                      id="city"
                      name="city"
                      value={values.city}
                      placeholder="Musterstadt"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="country" className="form-label">
                    Land:
                    <input
                      onChange={onChange}
                      type="text"
                      className="form-input"
                      id="country"
                      name="country"
                      value={values.country}
                      placeholder="Musterland"
                      required
                    />
                  </label>
                </div>

                <div className="">
                  <label htmlFor="number" className="form-label">
                    Telefonnummer:
                    <input
                      onChange={onChange}
                      type="text"
                      className="form-input"
                      id="number"
                      name="number"
                      value={values.number}
                      placeholder="01234 5678912"
                      required
                    />
                  </label>
                </div>

                <br />
                <button type="submit" className="btn-gl">
                  Absenden
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
