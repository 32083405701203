import React from 'react';
import './uberuns.css';

const AboutUsComponent = () => {
  return (
    <div className="about-section">
      <div className="about-container">
        {/* Über uns */}
        <div className="about-text">
          <h2 className="about-title">Über uns</h2>
          <p className="about-description">
            Unsere Plattform wurde entwickelt, um es Event-Organisatoren leicht zu machen, die schönsten Momente ihrer
            Veranstaltungen zu sammeln und mit allen Teilnehmern zu teilen. Wir glauben daran, dass jeder Moment es
            wert ist, festgehalten zu werden und machen es Ihnen einfach, diese Erinnerungen digital zu speichern und zu
            teilen.
          </p>
        </div>
        {/* Unsere Mission */}
        <div className="about-text">
          <h2 className="about-title">Unsere Mission</h2>
          <p className="about-description">
            Unsere Mission ist es, eine einfache, sichere und benutzerfreundliche Lösung bereitzustellen, die es
            Menschen ermöglicht, Erinnerungen von Events zu teilen. Wir wollen sicherstellen, dass keiner der
            wertvollen Momente verloren geht.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsComponent;
