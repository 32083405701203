import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const UserTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null); // Zustand für das ausgewählte Ticket
  const [message, setMessage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(""); // Zustand für ausgewählten Status
  const [selectedTicketId, setSelectedTicketId] = useState(""); // Zustand für ausgewählte Ticket-ID

  // Holen der userId aus dem Redux-Store
  const userId = useSelector((state) => state.user.userId);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/tickets/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setTickets(data); // Tickets aus der API abrufen
        } else {
          setMessage(`Fehler: ${data.error}`);
        }
      } catch (error) {
        setMessage("Fehler beim Laden der Tickets.");
      }
    };

    if (userId) {
      fetchTickets();
    }
  }, [userId]);

  // Funktion zum Filtern der Tickets nach Status
  const handleStatusChange = (e) => {
    const selected = e.target.value;
    setSelectedStatus(selected);
    setSelectedTicketId(""); // Zurücksetzen, wenn der Status geändert wird
    setSelectedTicket(null); // Zurücksetzen, wenn der Status geändert wird

    if (selected === "") {
      setFilteredTickets(tickets); // Alle Tickets anzeigen, wenn kein Status ausgewählt ist
    } else {
      const filtered = tickets.filter((ticket) => ticket.status === selected);
      setFilteredTickets(filtered); // Gefilterte Tickets nach Status anzeigen
    }
  };

  // Funktion, die den ausgewählten Ticket-Details lädt
  const handleTicketSelect = (e) => {
    const ticketId = e.target.value;
    setSelectedTicketId(ticketId);

    const selected = filteredTickets.find(
      (ticket) => ticket.ticket_id === parseInt(ticketId, 10)
    );
    setSelectedTicket(selected); // Das ausgewählte Ticket anzeigen
  };

  // Definiere alle möglichen Status, um sie im Dropdown anzuzeigen
  const allStatuses = ["open", "in_progress", "closed"];

  // Wenn in den Tickets noch nicht alle Status vorhanden sind, fügen wir sie hinzu
  const uniqueStatuses = [
    ...new Set([...allStatuses, ...tickets.map((ticket) => ticket.status)]),
  ];

  return (
    <>
      <div className="">
        <div className="CardContainerTop">
          <div>
            <div className="CardContainer">
              <div className="CardContainerTitle">
                <h2>Meine Tickets</h2>
              </div>

              {/* Dropdown für den Ticket-Status */}
              <div>
                <label htmlFor="status-filter">Status:</label>
                <select
                  className="form-select"
                  id="status-filter"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="">-- Status auswählen --</option>
                  {uniqueStatuses.map((status) => (
                    <option key={status} value={status}>
                      {status === "open"
                        ? "Offen"
                        : status === "in_progress"
                        ? "In Bearbeitung"
                        : "Geschlossen"}
                    </option>
                  ))}
                </select>
              </div>

              {/* Dropdown für die Ticket-Auswahl basierend auf dem Status */}
              {selectedStatus && filteredTickets.length > 0 && (
                <div>
                  <label htmlFor="ticket-filter">Ticket:</label>
                  <select
                    className="form-select"
                    id="ticket-filter"
                    value={selectedTicketId}
                    onChange={handleTicketSelect}
                  >
                    <option value="">-- Ticket auswählen --</option>
                    {filteredTickets.map((ticket) => (
                      <option key={ticket.ticket_id} value={ticket.ticket_id}>
                        {`${ticket.title} (ID: ${ticket.ticket_id})`}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Anzeige des ausgewählten Tickets */}
              {selectedTicket && (
                <div className="ticket-details CardContainer21">
                  <form>
                    <div className="CardContainerTitle">
                      <h2>{selectedTicket.title}</h2>
                    </div>
                    <p>{selectedTicket.description}</p>
                    <br />
                    <p>
                      Status:{" "}
                      {selectedTicket.status === "open"
                        ? "Offen"
                        : selectedTicket.status === "in_progress"
                        ? "In Bearbeitung"
                        : "Geschlossen"}
                    </p>
                  </form>
                </div>
              )}

              {message && <p>{message}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTickets;
