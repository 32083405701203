import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./../../../../function/authService";
import { getUserById } from "../../../../api/auth";
import "./profiledropdown.css";

const DropdownImageTriggerExample = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null); // Zustand für den Benutzer
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const { userId } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const data = await getUserById(userId);
        setUser(data);
      } catch (error) {
        console.error("Fehler beim Laden des Benutzers:", error);
      }
    };

    if (userId) {
      fetchUser();
    }
  }, [userId]);

  const toggleDropdown = () => setOpen(!open);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const trigger = (
    <span className="trigger">
      <img
        className="avatar"
        src={
          user && user.image_url
            ? user.image_url
            : "https://via.placeholder.com/150"
        }
        alt="avatar"
      />
    </span>
  );

  const options = [
    {
      key: "user",
      text: "Profil",
      icon: "Profil",
      to: "/user/profile/settings",
    },
    {
      key: "settings",
      text: "Zahlungen",
      icon: "settings",
      to: "https://billing.stripe.com/p/login/test_8wMcPa8ZY8hb8DK144",
    },
  ];

  return (
    <div className="profiledropdown" ref={dropdownRef}>
      <div onClick={toggleDropdown}>{trigger}</div>
      {open && (
        <ul className="dropdown-menu">
          {options.map((option) => (
            <li key={option.key} className="dropdown-item">
              <NavLink to={option.to}>
                <i className={`icon ${option.icon}`}></i> {option.text}
              </NavLink>
            </li>
          ))}
          <li key="sign-out" className="dropdown-item">
            <NavLink to="/signup" className="logout-link">
              <button onClick={() => logout(dispatch)} className="btn-gl">
                Logout
              </button>
            </NavLink>
          </li>
        </ul>
      )}
    </div>
  );
};

export default DropdownImageTriggerExample;
