import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../components/Navbar/navbar";
import "./../styles/NotFound.css";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <section className="Top">
        <div className="notfound-wrapper">
          <div className="notfound-card">
            <div className="not-found">
              <h2>Hoppla! Du scheinst dich verirrt zu haben..</h2>
              <p>Hier sind einige hilfreiche Links:</p>
              <div className="not-found-nav">
                <ul>
                  <li>
                    <NavLink className="not-found-nav-item" to="/">
                      Startseite
                    </NavLink>
                    <NavLink className="not-found-nav-item" to="/home">
                      Dashboard
                    </NavLink>
                    <NavLink className="not-found-nav-item" to="/signup">
                      Login
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
