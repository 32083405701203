import React from "react";
import CPicture from "./picture";
import GetEvents from "./getevents";
import Layout from "../../../components/layout";

const Indexevents = () => {
  return (
    <Layout>
      <div className="">
        <div className="Padding3 Top1">
          <GetEvents />
          <br />
          <CPicture />
        </div>
      </div>
    </Layout>
  );
};

export default Indexevents;
