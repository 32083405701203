//import Footer from "./Footer/Footer";
import Navbar from "./Navbar/navbar";

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <div className="">{children}</div>

    </div>
  );
};

export default Layout;
