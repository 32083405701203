import React from 'react'
import Hero from '../components/Home/Hero/Hero'
import Layout from '../components/layout'
import About from "./../components/Home/About/about";
import Text from "./../components/Home/Text/text";
import Pricling from "../components/Home/Preise/Pakete";
import Uberuns from "./../components/Home/uberuns/uberuns";
import Slogan from "./../components/Home/slogan/Slogan";
import FAQ from "./../components/Home/faq/faq";
import Footer from "./../components/Home/Footer/Footer";

const Startseite = () => {
  return (
    <Layout>
      <Hero />
      <Text />
      <About />
      <Slogan />
      <Uberuns />
      <Pricling />
      <FAQ />
      <Footer />
    </Layout>
  )
}

export default Startseite