//removeworker.js
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const ResetWorkerRole = () => {
  const [workers, setWorkers] = useState([]);
  const [selectedWorkerId, setSelectedWorkerId] = useState("");
  const [message, setMessage] = useState("");
  const { companyId } = useSelector((state) => state.company); // Get companyId from Redux store

  // Function to fetch all workers for the specific company when the component loads
  useEffect(() => {
    if (companyId) {
      const fetchWorkers = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/workers/${companyId}`
          );
          const data = await response.json();

          if (Array.isArray(data)) {
            setWorkers(data);
          } else {
            console.error("Unexpected response format:", data);
            setWorkers([]);
          }
        } catch (error) {
          console.error("Error fetching workers:", error);
          setWorkers([]);
        }
      };

      fetchWorkers();
    }
  }, [companyId]); // Re-fetch workers whenever companyId changes

  // Function to reset the role of a selected worker
  const handleResetRoleById = async (e) => {
    e.preventDefault();
    if (!companyId) {
      setMessage("Company ID is required");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/reset-role/id/${companyId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: selectedWorkerId }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setMessage(
          `Rolle erfolgreich zurückgesetzt für Nutzer ID: ${data.worker.user_id}`
        );
        // Optionally update the worker list after resetting the role
        setWorkers(
          workers.filter((worker) => worker.user_id !== data.worker.user_id)
        );
        setSelectedWorkerId(""); // Reset the dropdown
      } else {
        setMessage(data.error || "Fehler beim Zurücksetzen der Rolle");
      }
    } catch (error) {
      setMessage("Serverfehler");
    }
  };

  return (
    <div className="Padding3">
      <div className="CardContainer">
        <form>
          <div className="CardContainerTitle">
            <h2>Mitarbeiter entfernen</h2>
          </div>

          <div>
            <p>Mitarbeiter wählen:</p>
            <select
              className="form-select"
              value={selectedWorkerId}
              onChange={(e) => setSelectedWorkerId(e.target.value)}
              disabled={!workers.length}
            >
              <option value="" disabled>
                Mitarbeiter auswählen
              </option>
              {workers.map((worker) => (
                <option key={worker.user_id} value={worker.user_id}>
                  {worker.email} (ID: {worker.user_id})
                </option>
              ))}
            </select>
            <div>
              <button
                className="btn-gl"
                onClick={handleResetRoleById}
                disabled={!selectedWorkerId}
              >
                Entfernen
              </button>
            </div>
          </div>

          {message && <p>{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default ResetWorkerRole;
