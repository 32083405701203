import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Checkout from "./../../checkout/checkout";

const CreateEventForm = () => {
  const { userId } = useSelector((state) => state.user);
  const [eventData, setEventData] = useState({
    title: "",
    description: "",
    location: "",
    date: "",
    time: "",
    max_images_per_user: "",
  });
  const [requiresPayment, setRequiresPayment] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/createevent/${userId}`,
        eventData
      );
      if (response.data.status === "success") {
        alert("Event erfolgreich erstellt");
      } else {
        alert("Fehler beim Erstellen des Events");
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 403) {
        setRequiresPayment(true); // Wenn 403, dann auf Zahlung weiterleiten
      } else if (err.response && err.response.status === 404) {
        alert("Benutzer oder Creator-Informationen nicht gefunden");
      } else {
        alert("Fehler während Erstellen des Events");
      }
    }
  };

  return (
    <div className="CardContainer">
      {requiresPayment ? (
        <Checkout plan="starter" />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="CardContainerTitle">
            <h1>Event erstellen</h1>
          </div>
          <div>
            <label>Event Titel:</label>
            <input
              type="text"
              name="title"
              value={eventData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Beschreibung:</label>
            <textarea
              name="description"
              value={eventData.description}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div>
            <label>Ort:</label>
            <input
              type="text"
              name="location"
              value={eventData.location}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Datum:</label>
            <input
              type="date"
              name="date"
              value={eventData.date}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Beginn:</label>
            <input
              type="time"
              name="time"
              value={eventData.time}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Maximale Anzahl von Bildern pro Gast:</label>
            <select
              name="max_images_per_user"
              className="form-select"
              value={eventData.max_images_per_user}
              onChange={handleChange}
              required
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
          <br/>
          <button className="btn-gl" type="submit">
            Event erstellen
          </button>
        </form>
      )}
    </div>
  );
};

export default CreateEventForm;
