import { createSlice } from "@reduxjs/toolkit";

// Helper-Funktion, um die ID und Rolle aus dem Local Storage zu holen
const loadRoleFromLocalStorage = (roleName) => {
    const id = localStorage.getItem(`${roleName}Id`);
    const role = localStorage.getItem(`${roleName}Role`);
    return {
        id: id ? parseInt(id, 10) : null,
        role: role || null,
    };
};

// Initialer Zustand für die verschiedenen Rollen
const initialRolesState = {
    worker: loadRoleFromLocalStorage("worker"),
    staff: loadRoleFromLocalStorage("staff"),
    creator: loadRoleFromLocalStorage("creator"),
};

export const rolesSlice = createSlice({
    name: "roles",
    initialState: initialRolesState,
    reducers: {
        setWorker: (state, action) => {
            state.worker.id = action.payload.id;
            state.worker.role = action.payload.role;
            // Speichere Worker-Daten im Local Storage
            localStorage.setItem("workerId", action.payload.id);
            localStorage.setItem("workerRole", action.payload.role);
        },
        setStaff: (state, action) => {
            state.staff.id = action.payload.id;
            state.staff.role = action.payload.role;
            // Speichere Staff-Daten im Local Storage
            localStorage.setItem("staffId", action.payload.id);
            localStorage.setItem("staffRole", action.payload.role);
        },
        setCreator: (state, action) => {
            state.creator.id = action.payload.id;
            state.creator.role = action.payload.role;
            // Speichere Creator-Daten im Local Storage
            localStorage.setItem("creatorId", action.payload.id);
            localStorage.setItem("creatorRole", action.payload.role);
        },
    },
});

// Exportiere den Slice selbst (für Aktionen) und den Reducer als Standardexport
export const { setWorker, setStaff, setCreator } = rolesSlice.actions;
export const rolesReducer = rolesSlice.reducer;
