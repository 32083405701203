import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Profile from "./Dropdown/Profile/ProfileDropdown";
import { FaHome, FaUser, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { FiHome, FiLogIn } from "react-icons/fi";
import { FaEuroSign } from "react-icons/fa6";
import { FaRocketchat } from "react-icons/fa6";
import Logo from "./../../images/logo2.png";
import { Button } from "./Button";
import "./Navbar.css";
import { logout } from "./../../function/authService";

const Navbar = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Verwende useNavigate für Navigation

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const { userRole } = useSelector((state) => state.role);
  const isAdmin = userRole === "admin";
  const isUser = userRole === "user";
  const isWorker = userRole === "worker";
  const isCompany = userRole === "company";

  // Funktion zum smoothen Scrollen ohne href
  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
    }
  };

  // Funktion zur Navigation und Scrollen auf der Startseite
  const handleNavigation = (path, id) => {
    navigate(path);
    setTimeout(() => {
      handleScrollTo(id);
    }, 300); // Timeout zum Laden der neuen Seite
  };

  return (
    <nav>
      <div className="navbar">
        {isAuth ? (
          <>
            <NavLink to="/home" className="navbar-logo">
              <img src={Logo} alt="Memoreyess Logo" className="logo-image" />
            </NavLink>
            <ul className="nav-menu">
              <li className="nav-item">
                <NavLink to="/home" className="nav-links">
                  <FaHome className="nav-icon" />
                  <span className="nav-text">Home</span>
                </NavLink>
              </li>
              {isUser && (
                <li className="nav-item">
                  <NavLink to="/dashboard" className="nav-links">
                    <FaUser className="nav-icon" />
                    <span className="nav-text">Dashboard</span>
                  </NavLink>
                </li>
              )}
              {isWorker && (
                <li className="nav-item">
                  <NavLink to="/dashboard" className="nav-links">
                    <FaUser className="nav-icon" />
                    <span className="nav-text">Dashboard</span>
                  </NavLink>
                </li>
              )}
              {isCompany && (
                <li className="nav-item">
                  <NavLink to="/dashboard" className="nav-links">
                    <FaUser className="nav-icon" />
                    <span className="nav-text">Dashboard</span>
                  </NavLink>
                </li>
              )}
              {isAdmin && (
                <li className="nav-item">
                  <NavLink to="/dashboard" className="nav-links">
                    <FaUser className="nav-icon" />
                    <span className="nav-text">Dashboard</span>
                  </NavLink>
                </li>
              )}
              <li className="nav-item">
                <NavLink to="/support" className="nav-links">
                  <FaCog className="nav-icon" />
                  <span className="nav-text">Support</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/Startseite" className="nav-links" onClick={() => logout(dispatch)}>
                  <FaSignOutAlt className="nav-icon" />
                  <span className="nav-text"><div className="nav-text-l">Logout</div></span>
                </NavLink>
              </li>
            </ul>
            <Profile className="navbar-profile" />
          </>
        ) : (
          <>
            <NavLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img src={Logo} alt="Memoreyess Logo" className="logo-image" />
            </NavLink>
            <div className="menu-icon" onClick={handleClick}>
              {/* Menu icon can be added here */}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <span onClick={() => handleNavigation("/", "home")} className="nav-links">
                  <FiHome className="nav-icon" />
                  <span className="nav-text">Home</span>
                </span>
              </li>
              <li className="nav-item">
                <span onClick={() => handleNavigation("/", "preise")} className="nav-links">
                  <FaEuroSign className="nav-icon" />
                  <span className="nav-text">Preise</span>
                </span>
              </li>
              <li className="nav-item">
                <span onClick={() => handleNavigation("/", "faq")} className="nav-links">
                  <FaRocketchat className="nav-icon" />
                  <span className="nav-text">FAQ</span>
                </span>
              </li>
              <li className="nav-item">
                <NavLink to="/signup" className="nav-links">
                  <FiLogIn className="nav-icon" />
                  <span className="nav-text"><div className="nav-text-l">Anmelden</div></span>
                </NavLink>
              </li>
            </ul>
            <Button />
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
