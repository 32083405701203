//Profil.js
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Layout from "../components/layout";
import { getProtectedInfo } from "../function/authService";

const Profil = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [protectedData, setProtectedData] = useState(null);

  useEffect(() => {
    getProtectedInfo(dispatch, setProtectedData, setLoading);
  }, [dispatch]);

  return loading ? (
    <Layout>
      <h1>Loading...</h1>
    </Layout>
  ) : (
    <div>
      <Layout>
        <h1>Profil</h1>
        <h2>{protectedData}</h2>
      </Layout>
    </div>
  );
};

export default Profil;
