import React, { useState } from "react";
import { useSelector } from "react-redux";

const CreateTicket = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");

  // Holen der userId aus dem Redux-Store
  const userId = useSelector((state) => state.user.userId); // Zugriff auf die userId aus dem Store

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/tickets/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ title, description }), // Titel und Beschreibung des Tickets
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMessage("Ticket erfolgreich erstellt");
        setTitle(""); // Zurücksetzen der Felder nach erfolgreicher Erstellung
        setDescription(""); // Zurücksetzen der Felder nach erfolgreicher Erstellung
      } else {
        setMessage(`Fehler: ${data.error}`);
      }
    } catch (error) {
      setMessage("Serverfehler");
    }
  };

  return (
    <>
      <div className="Top">
        <div className="CardContainerTop">
          <div className="CardContainer">
            <div className="CardContainerTitle">
              <h2>Neues Ticket erstellen</h2>
            </div>

            <form onSubmit={handleSubmit}>
              <div>
                <label>Titel</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Beschreibung</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>
              <button className="btn-gl" type="submit">
                Ticket erstellen
              </button>
            </form>
            {message && <p>{message}</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTicket;
