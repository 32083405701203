import React from 'react'
import Layout from "../../../components/layout"
import AddStaff from './addstaff'
import RemoveStaff from './removestaff'

const IndexStaff = () => {
  return (
    <Layout>
      <div className="Top ">
        <AddStaff />
        <br />
        <div className="">
          <RemoveStaff />
        </div>
      </div>
    </Layout>
  )
}

export default IndexStaff;
