import React from "react";
import "./pakete.css"; // Neue CSS-Datei

const PaketeComponent = () => {
  return (
    <div className="paket-bereich">
      <h1 className="paket-überschrift">Wähle das passende Memoreyess-Paket</h1>
      <div className="paket-optionen-container">
        {/* Basis-Paket */}
        <div className="paket-karte basis-paket">
          <h2 className="paket-name">Basis-Paket</h2>
          <ul className="paket-details">
            <li>Für ein einmaliges Event</li>
            <li>Ein Event erstellen und verwalten</li>
            <li>Volle Kontrolle über hochgeladene Bilder</li>
          </ul>
          <p className="alter-preis">Statt 50€</p>
          <p className="neuer-preis">Jetzt nur 24,45€</p>
        </div>

        {/* Pro-Paket */}
        <div className="paket-karte pro-paket">
          <h2 className="paket-name">Pro-Paket</h2>
          <ul className="paket-details">
            <li>Für regelmäßige Eventplaner</li>
            <li>Unbegrenzte Events erstellen und verwalten</li>
            <li>Monatliche Abrechnung</li>
          </ul>
          <p className="alter-preis">Statt 200€</p>
          <p className="neuer-preis">Jetzt nur 144,95€</p>
          <p className="paket-nachricht">
            Wähle das Pro-Paket und verwalte unbegrenzt viele Events!
          </p>
        </div>

        {/* White-Label Option */}
        <div className="paket-karte whitelabel-paket" onClick={() => window.location.href = "mailto:info@q-tech.dev?subject=Anfrage%20zur%20Whitelabel%20Lösung&body=Bitte%20geben%20Sie%20uns%20mehr%20Informationen%20zur%20Whitelabel-Lösung."}>
          <h2 className="paket-name">White-Label-Lösung</h2>
          <ul className="paket-details">
            <li>Für Unternehmen oder Agenturen</li>
            <li>Unbegrenzte Events mit deinem eigenen Branding</li>
            <li>Volle Kontrolle über Teilnehmer und Bilder</li>
            <li>Individuelle Anpassungen und Funktionen</li>
          </ul>
          <p className="paket-preis">Preis: Auf Anfrage</p>
        </div>
      </div>
    </div>
  );
};

export default PaketeComponent;
