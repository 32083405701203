import React, { useState } from "react";
import { useSelector } from "react-redux";

const UpdateTicketStatus = ({ ticketId }) => {
  const [status, setStatus] = useState("open");
  const [message, setMessage] = useState("");

  const userId = useSelector((state) => state.user.userId); // Holen der userId aus dem Redux-Store
  const role = useSelector((state) => state.user.role); // Angenommen, die Rolle wird im Store gespeichert

  const handleStatusChange = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/tickets/${ticketId}/${userId}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status, role }), // Status und Rolle übergeben
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMessage("Ticketstatus erfolgreich aktualisiert.");
      } else {
        setMessage(`Fehler: ${data.error}`);
      }
    } catch (error) {
      setMessage("Fehler beim Aktualisieren des Ticketstatus.");
    }
  };

  return (
    <div className="CardContainerTop">
      <div className="CardContainer">
        <div className="CardContainerTitle">
          <h2>Ticketstatus ändern</h2>
        </div>
        <form onSubmit={handleStatusChange}>
          <div>
            <label>Neuer Status</label>
            <select
              className="form-select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="open">Offen</option>
              <option value="in_progress">In Bearbeitung</option>
              <option value="closed">Geschlossen</option>
            </select>
          </div>
          <button className="btn-gl" type="submit">
            Status aktualisieren
          </button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default UpdateTicketStatus;
