//App.js
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { useSelector } from "react-redux";

import "./App.css";
import NotFound from "./pages/NotFound";
import NotRights from "./pages/NotRights";

import Signup from "./pages/signup";
import RequestPasswordReset from "./components/Signup/Login/requestPasswordReset";
import ResetPassword from "./components/Signup/Login/ResetPassword";
import OtpVerification from "./components/Signup/register/otpVerification";

import Profil from "./private/profil";
import Home1 from "./private/home";
import Service from "./private/service";

import UProfile from "./private/dashboardcomponents/userprofil/uProfil";

import UserProfile from "./private/dashboardcomponents/userprofil/userProfile";
import Profilbild from "./private/dashboardcomponents/userprofil/profilbild";
import IndexEvents from "./private/dashboardcomponents/upload/indexevents";

import Preise from "./pages/Preise";
import SuccessPage from "./pages/components/succespage";
import CancelPage from "./pages/components/cancel";

import EventDashboard from "./private/dashboardcomponents/events/indexevent";
import QRCodeG from "./private/dashboardcomponents/events/qrgenerator";
import IndexWorker from "./private/dashboardcomponents/addworker/indexworker";
import Galleryopen from "./private/dashboardcomponents/gallery/galleryopen";
import Gallery from "./private/dashboardcomponents/gallery/gallery";
import Indexstaff from "./private/dashboardcomponents/addstaff/indexstaff";
import DashboardGL from "./private/Dashboard/dashboard";

import AdminLimit from "./private/dashboardcomponents/admin/adminlimit";

import Indexticket from "./private/dashboardcomponents/tickets/indexticket";
import CreateTicket from "./private/dashboardcomponents/tickets/createticket";
import UserTickets from "./private/dashboardcomponents/tickets/userticket";
import TicketResponses from "./private/dashboardcomponents/tickets/ticketresponse";
import UpdateTicketStatus from "./private/dashboardcomponents/tickets/ticketstatus";

import Home from "./pages/Startseite";

const PrivateRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return <>{isAuth ? <Outlet /> : <Navigate to="/startseite" />}</>;
};

const RestrictedRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return <>{!isAuth ? <Outlet /> : <Navigate to="/home" />}</>;
};

const App = () => {
  const { userRole } = useSelector((state) => state.role);
  const { worker, staff, creator } = useSelector((state) => state.roles);
  const isAdmin = userRole === "admin";
  const isUser = userRole === "user";
  const isCompany = userRole === "company";
  const isWorker = worker?.role === "worker";
  const isStaff = staff?.role === "staff";
  const isCreator = creator?.role === "creator";

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          {isAdmin ? (
            <>
              <Route path="/dashboard" element={<DashboardGL />} />
              <Route path="/user/profile/settings" element={<UProfile />} />
              <Route path="/user/profile/data" element={<UserProfile />} />
              <Route path="/user/profile/picture" element={<Profilbild />} />

              <Route path="/admin/limit" element={<AdminLimit />} />

              <Route path="/dashboard/event" element={<EventDashboard />} />
              <Route path="/dashboard/event/qrgenerator" element={<QRCodeG />}/>
              <Route path="/dashboard/gallery/:eventId" element={<Gallery />} />
              <Route path="/dashboard/gallery" element={<Galleryopen />} />
              <Route path="/dashboard/worker" element={<IndexWorker />} />
              <Route path="/dashboard/staff" element={<Indexstaff />} />
              <Route path="/user/event/:id" element={<IndexEvents />} />

              <Route path="/preisliste" element={<Preise />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/cancel" element={<CancelPage />} />

              <Route path="/dashboard/ticket" element={<Indexticket />} />
              <Route path="/dashboard/tickets/create" element={<CreateTicket />} />
              <Route path="/dashboard/tickets/:ticket_id" element={<TicketResponses />} />
              <Route path="/dashboard/tickets/status/:ticket_id" element={<UpdateTicketStatus />} />
              <Route path="/dashboard/tickets" element={<UserTickets />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}
          {isUser ? (
            <>
              <Route path="/dashboard" element={<DashboardGL />} />
              <Route path="/user/profile/settings" element={<UProfile />} />
              <Route path="/user/profile/data" element={<UserProfile />} />
              <Route path="/user/profile/picture" element={<Profilbild />} />

              <Route path="/user/event/:id" element={<IndexEvents />} />
              <Route path="/dashboard/event" element={<EventDashboard />} />

              <Route path="/preisliste" element={<Preise />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/cancel" element={<CancelPage />} />

              <Route path="/dashboard/ticket" element={<Indexticket />} />
              <Route path="/dashboard/tickets/create" element={<CreateTicket />} />
              <Route path="/dashboard/tickets/:ticket_id" element={<TicketResponses />} />
              <Route path="/dashboard/tickets" element={<UserTickets />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}
          {isCompany ? (
            <>
              <Route path="/dashboard" element={<DashboardGL />} />
              <Route path="/user/profile/settings" element={<UProfile />} />
              <Route path="/user/profile/data" element={<UserProfile />} />
              <Route path="/user/profile/picture" element={<Profilbild />} />

              <Route path="/user/event/:id" element={<IndexEvents />} />
              <Route path="/dashboard/event" element={<EventDashboard />} />
              <Route path="/dashboard/worker" element={<IndexWorker />} />
              <Route path="/dashboard/event/qrgenerator" element={<QRCodeG />}/>
              <Route path="/dashboard/gallery" element={<Galleryopen />} />
              <Route path="/dashboard/gallery/:eventId" element={<Gallery />} />
              <Route path="/dashboard/ticket" element={<Indexticket />} />

              <Route path="/preisliste" element={<Preise />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/cancel" element={<CancelPage />} />

              <Route path="/dashboard/ticket" element={<Indexticket />} />
              <Route path="/dashboard/tickets/create" element={<CreateTicket />} />
              <Route path="/dashboard/tickets/:ticket_id" element={<TicketResponses />} />
              <Route path="/dashboard/tickets" element={<UserTickets />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}
          {isWorker ? (
            <>
              <Route path="/dashboard" element={<DashboardGL />} />
              <Route path="/user/profile/settings" element={<UProfile />} />
              <Route path="/user/profile/data" element={<UserProfile />} />
              <Route path="/user/profile/picture" element={<Profilbild />} />

              <Route path="/user/event/:id" element={<IndexEvents />} />
              <Route path="/dashboard/event/qrgenerator" element={<QRCodeG />}/>
              <Route path="/dashboard/gallery" element={<Galleryopen />} />
              <Route path="/dashboard/gallery/:eventId" element={<Gallery />} />
              <Route path="/dashboard/ticket" element={<Indexticket />} />

              <Route path="/preisliste" element={<Preise />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/cancel" element={<CancelPage />} />

              <Route path="/dashboard/ticket" element={<Indexticket />} />
              <Route path="/dashboard/tickets/create" element={<CreateTicket />} />
              <Route path="/dashboard/tickets/:ticket_id" element={<TicketResponses />} />
              <Route path="/dashboard/tickets" element={<UserTickets />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}
          {isCreator ? (
            <>
              <Route path="/dashboard" element={<DashboardGL />} />
              <Route path="/user/profile/settings" element={<UProfile />} />
              <Route path="/user/profile/data" element={<UserProfile />} />
              <Route path="/user/profile/picture" element={<Profilbild />} />
              
              <Route path="/dashboard/event" element={<EventDashboard />} />
              <Route path="/dashboard/event/qrgenerator" element={<QRCodeG />}/>
              <Route path="/dashboard/gallery/:eventId" element={<Gallery />} />
              <Route path="/dashboard/gallery" element={<Galleryopen />} />
              <Route path="/dashboard/staff" element={<Indexstaff />} />
              <Route path="/user/event/:id" element={<IndexEvents />} />
              <Route path="/dashboard/ticket" element={<Indexticket />} />

              <Route path="/preisliste" element={<Preise />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/cancel" element={<CancelPage />} />

              <Route path="/dashboard/ticket" element={<Indexticket />} />
              <Route path="/dashboard/tickets/create" element={<CreateTicket />} />
              <Route path="/dashboard/tickets/:ticket_id" element={<TicketResponses />} />
              <Route path="/dashboard/tickets" element={<UserTickets />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}
          {isStaff ? (
            <>
              <Route path="/dashboard" element={<DashboardGL />} />
              <Route path="/user/profile/settings" element={<UProfile />} />
              <Route path="/user/profile/data" element={<UserProfile />} />
              <Route path="/user/profile/picture" element={<Profilbild />} />

              <Route path="/dashboard/event" element={<EventDashboard />} />
              <Route path="/dashboard/gallery/:eventId" element={<Gallery />} />
              <Route path="/dashboard/gallery" element={<Galleryopen />} />
              <Route path="/user/event/:id" element={<IndexEvents />} />
              <Route path="/dashboard/ticket" element={<Indexticket />} />

              <Route path="/preisliste" element={<Preise />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/cancel" element={<CancelPage />} />

              <Route path="/dashboard/ticket" element={<Indexticket />} />
              <Route path="/dashboard/tickets/create" element={<CreateTicket />} />
              <Route path="/dashboard/tickets/:ticket_id" element={<TicketResponses />} />
              <Route path="/dashboard/tickets" element={<UserTickets />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}

          <Route path="/home" element={<Home1 />} />
          <Route path="/profil" element={<Profil />} />
          <Route path="/service" element={<Service />} />
        </Route>

        <Route element={<RestrictedRoutes />}>
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/request-password-reset"
            element={<RequestPasswordReset />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route path="/startseite" element={<Home />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
