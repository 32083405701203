import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Logo und kurze Beschreibung */}
        <div className="footer-section">
          <h2 className="footer-logo">Memoreyess</h2>
          <p className="footer-description">
           Die smarte Lösung für Event- und Bildverwaltung.
          </p>
        </div>

        {/* Navigation Links */}
        <div className="footer-section">
          <h3 className="footer-heading">Navigation</h3>
          <ul className="footer-links">
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#preise">Preise</a>
            </li>
            <li>
              <a href="#faq">FAQ</a>
            </li>
          </ul>
        </div>

        {/* Nützliche Links */}
        <div className="footer-section">
          <h3 className="footer-heading">Nützliche Links</h3>
          <ul className="footer-links">
            <li>
              <a href="/impressum">Impressum</a>
            </li>
            <li>
              <a href="/agb">AGB</a>
            </li>
            <li>
              <a href="/datenschutz">Datenschutz</a>
            </li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div className="footer-section">
          <h3 className="footer-heading">Folge uns</h3>
          <div className="footer-social-media">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      
      {/* Copyright */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Memoreyess. Alle Rechte vorbehalten.</p>
      </div>
    </footer>
  );
};

export default Footer;
