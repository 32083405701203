import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../components/layout";
import QRCODES from "./dashboardcomponents/events/qrcode";

const Home = () => {
  const userId = useSelector((state) => state.user.userId); // User ID aus dem Redux-Store
  const [userName, setUserName] = useState(""); // State zum Speichern des Benutzernamens

  // Funktion zum Abrufen der Benutzerdaten von der API
  const fetchUserName = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getusernamebyuserid/${userId}`
      );
      const data = await response.json();
      if (data && data.name) {
        setUserName(data.name); // Den Namen des Benutzers im State speichern
      }
    } catch (error) {
      console.error("Fehler beim Abrufen des Benutzernamens:", error);
    }
  };
  

  // useEffect zum Abrufen der Benutzerdaten, wenn die Komponente geladen wird
  useEffect(() => {
    if (userId) {
      fetchUserName();
    }
  }, [userId]);


  return (
    <Layout>
      <div className="Top Bottom1">
        <div className="CardContainerTop">
          <div className="CardContainer CardContainer21">
            <div className="CardContainerTitle">
              {/* Begrüßung basierend auf dem Benutzernamen */}
              <h3>Willkommen bei Memoreyess, <br/> {userName ? ` ${userName}!` : "!"}</h3>
            </div>
            <p>Schön, dass Sie wieder da sind.</p>
          </div>
        </div>
        <br />
        <div className="Padding2">
          <QRCODES />
        </div>
      </div>
    </Layout>
  );
};

export default Home;
