import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Joyride from "react-joyride"; // Joyride importieren
import { toast } from "react-toastify"; 

const AddStaff = () => {
  const [inputValue, setInputValue] = useState("");
  const [ setMessage] = useState("");
  const [events, setEvents] = useState([]); // State to store the user's events
  const [selectedEventId, setSelectedEventId] = useState(""); // State for selected event
  const { userId } = useSelector((state) => state.user); // Get user ID from Redux
  const [tourRunning, setTourRunning] = useState(false); // Zustand für Joyride

  // Fetch the user's events when the component loads
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/geteventsbyuserid/${userId}`
        );
        const data = await response.json();
        if (data && data.data && data.data.events.length > 0) {
          setEvents(data.data.events);
        } else {
          toast.error("Keine Events gefunden.");
        }
      } catch (error) {
        toast.error("Fehler beim Abrufen der Events.");
      }
    };

    fetchEvents();
  }, [userId]);

  const handleSetRole = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!selectedEventId) {
      toast.warn("Bitte ein Event auswählen.");
      return;
    }

    const isEmail = /\S+@\S+\.\S+/.test(inputValue);
    const url = isEmail
      ? `${process.env.REACT_APP_API_URL}/api/staff/set-role/email/${selectedEventId}`
      : `${process.env.REACT_APP_API_URL}/api/staff/set-role/id/${selectedEventId}`;

    const body = isEmail ? { email: inputValue } : { user_id: inputValue };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success(isEmail ? `Staff erfolgreich hinzugefügt` : `Staff erfolgreich hinzugefügt`);
      } else {
        toast.error(data.error || "Fehler beim Hinzufügen von Staff.");
      }
    } catch (error) {
      toast.error("Server-Fehler.");
    }
  };

  // Joyride steps
  const steps = [
    {
      target: ".CardContainerTitle", // Target the title "Personal hinzufügen"
      content: "Hier können Sie Personal hinzufügen, um die Event Galerie zu bearbeiten.",
    },
    {
      target: "#event-select", // Target the event dropdown
      content: "Wählen Sie ein Event aus, dem Sie Personal hinzufügen möchten.",
    },
    {
      target: "#input-field", // Target the input field for email/user ID
      content: "Geben Sie die E-Mail-Adresse oder Benutzer-ID des Mitarbeiters ein.",
    },
    {
      target: "#submit-button", // Target the submit button
      content: "Klicken Sie hier, um das Personal hinzuzufügen.",
    },
  ];

  // Überprüfen, ob die Tour bereits gesehen wurde
  useEffect(() => {
    const hasSeenTour = localStorage.getItem("hasSeenTourAddStaff");

    // Wenn die Tour nicht gesehen wurde, starte sie
    if (!hasSeenTour) {
      setTourRunning(true);
    }
  }, []);

  // Callback für Joyride
  const handleJoyrideCallback = (data) => {
    const { status, action } = data;

    // Beende die Tour, wenn der Benutzer sie abgeschlossen oder geschlossen hat
    if (status === "finished" || action === "close") {
      setTourRunning(false);
      localStorage.setItem("hasSeenTourAddStaff", "true"); // Markiere als gesehen
    }
  };

  return (
    <div className="CardContainerTop">
      <Joyride
        steps={steps} // Joyride-Schritte
        run={tourRunning} // Startet die Tour automatisch, wenn tourRunning true ist
        continuous
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <div className="CardContainer">
        <form onSubmit={handleSetRole}>
          <div className="CardContainerTitle">
            <h2>Personal hinzufügen</h2>
          </div>
          <div>
            <p>Event auswählen:</p>
            <select
              id="event-select" // Assign ID for Joyride
              className="form-select"
              value={selectedEventId}
              onChange={(e) => setSelectedEventId(e.target.value)}
            >
              <option value="">-- Event auswählen --</option>
              {events.map((event) => (
                <option key={event.event_id} value={event.event_id}>
                  {event.title} (ID: {event.event_id})
                </option>
              ))}
            </select>
          </div>
          <div>
            <p>Email oder Benutzer-ID:</p>
            <input
              id="input-field" // Assign ID for Joyride
              className="form-input"
              type="text"
              placeholder="Gib die Benutzer E-Mail oder ID ein"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button
              id="submit-button" // Assign ID for Joyride
              className="btn-gl"
              type="submit"
              disabled={!selectedEventId}
            >
              Hinzufügen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStaff;
