// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #b2adaa;
}

.btn-nav {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2); /* Halb-transparenter Hintergrund */
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px); /* Frostiger Glas-Effekt */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Leichter Schatten für Tiefe */
  transition: all 0.3s ease-out;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Dezenter Rand */
}

.btn-nav:hover {
  background-color: #1a1a1a;
  color: #00ffff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1); /* Hellerer Schatten beim Hover */
}

@media screen and (max-width: 960px) {
  .btn-res {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Button.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,eAAe;EACf,WAAW;EACX,eAAe;EACf,oCAAoC,EAAE,mCAAmC;EACzE,kCAA0B;UAA1B,0BAA0B,EAAE,0BAA0B;EACtD,wCAAwC,EAAE,gCAAgC;EAC1E,6BAA6B;EAC7B,0CAA0C,EAAE,kBAAkB;AAChE;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,yCAAyC,EAAE,iCAAiC;AAC9E;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[":root {\r\n  --primary: #b2adaa;\r\n}\r\n\r\n.btn-nav {\r\n  padding: 8px 20px;\r\n  border-radius: 4px;\r\n  outline: none;\r\n  border: none;\r\n  font-size: 18px;\r\n  color: #fff;\r\n  cursor: pointer;\r\n  background: rgba(255, 255, 255, 0.2); /* Halb-transparenter Hintergrund */\r\n  backdrop-filter: blur(8px); /* Frostiger Glas-Effekt */\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Leichter Schatten für Tiefe */\r\n  transition: all 0.3s ease-out;\r\n  border: 1px solid rgba(255, 255, 255, 0.3); /* Dezenter Rand */\r\n}\r\n\r\n.btn-nav:hover {\r\n  background-color: #1a1a1a;\r\n  color: #00ffff;\r\n  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1); /* Hellerer Schatten beim Hover */\r\n}\r\n\r\n@media screen and (max-width: 960px) {\r\n  .btn-res {\r\n    display: none;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
