import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./../../components/layout";

const CancelPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
        <section className="Top1">
        <div className="CardContainerTop">
        <div className="CardContainer" style={{ textAlign: "center", marginTop: "50px" }}>
          <div className="CardContainerTitle">
          <h1>Die Zahlung wurde abgebrochen</h1>
          </div>
          <p>
            Ihre Zahlung wurde abgebrochen. Bitte versuchen Sie es erneut.
          </p>
          <br/>
          <button className="btn-gl" onClick={() => navigate("/home")}>
            Zurück zur Startseite
          </button>
          </div>
        </div>
        </section>
    </Layout>
  );
};

export default CancelPage;
