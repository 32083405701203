// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.impressum-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #1a1a1a;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .impressum-content {
    max-width: 800px;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  
  .impressum-container h1 {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .impressum-container h2 {
    font-size: 1.8em;
    margin-top: 20px;
    color: #333;
  }
  
  .impressum-container h3 {
    font-size: 1.5em;
    margin-top: 15px;
    color: #333;
  }
  
  .impressum-container p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
  }
  
  .impressum-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .impressum-container a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/impressum.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,iBAAiB;IACjB,8BAA8B;EAChC;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,yCAAyC;EAC3C;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".impressum-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 20px;\r\n    background-color: #1a1a1a;\r\n    min-height: 100vh;\r\n    font-family: Arial, sans-serif;\r\n  }\r\n  \r\n  .impressum-content {\r\n    max-width: 800px;\r\n    width: 100%;\r\n    background: rgba(255, 255, 255, 0.8);\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .impressum-container h1 {\r\n    font-size: 2.5em;\r\n    text-align: center;\r\n    margin-bottom: 20px;\r\n    color: #333;\r\n  }\r\n  \r\n  .impressum-container h2 {\r\n    font-size: 1.8em;\r\n    margin-top: 20px;\r\n    color: #333;\r\n  }\r\n  \r\n  .impressum-container h3 {\r\n    font-size: 1.5em;\r\n    margin-top: 15px;\r\n    color: #333;\r\n  }\r\n  \r\n  .impressum-container p {\r\n    font-size: 1.1em;\r\n    line-height: 1.6;\r\n    color: #555;\r\n  }\r\n  \r\n  .impressum-container a {\r\n    color: #007bff;\r\n    text-decoration: none;\r\n  }\r\n  \r\n  .impressum-container a:hover {\r\n    text-decoration: underline;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
