import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/authSlice';
import { roleReducer } from './slices/userRoleSlice';
import { companyReducer } from './slices/company';
import { userReducer } from './slices/user';
import {rolesReducer} from './slices/rolesSlice'; // Importiere den rolesReducer

export const store = configureStore({
  reducer: {
    auth: authReducer,
    role: roleReducer,
    company: companyReducer,
    user: userReducer,
    roles: rolesReducer, // Füge den rolesReducer hinzu
  },
});
