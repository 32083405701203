import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Checkout from "./../../checkout/checkout";
import { toast } from "react-toastify";

const CreateEventForm = () => {
  const { userId } = useSelector((state) => state.user);
  const [eventData, setEventData] = useState({
    title: "",
    description: "",
    location: "",
    date: "",
    time: "",
    max_images_per_user: 10,
  });
  const [requiresPayment, setRequiresPayment] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Konvertiere max_images_per_user in einen Integer
    if (name === "max_images_per_user") {
      setEventData({
        ...eventData,
        [name]: parseInt(value, 10), // Wert in Integer konvertieren
      });
    } else {
      setEventData({
        ...eventData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/createevent/${userId}`,
        eventData
      );
      if (response.data.status === "success") {
        toast.success("Event erfolgreich erstellt!"); // Erfolgreiche Toast-Nachricht
      } else {
        toast.error("Fehler beim Erstellen des Events"); // Fehlerhafte Toast-Nachricht
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 403) {
        setRequiresPayment(true); // Wenn 403, dann auf Zahlung weiterleiten
        toast.warn("Zahlung erforderlich. Bitte einen Plan wählen."); // Warnung Toast-Nachricht
      } else if (err.response && err.response.status === 404) {
        toast.error("Benutzer oder Creator-Informationen nicht gefunden");
      } else {
        toast.error("Fehler während der Erstellung des Events");
      }
    }
  };

  return (
      <div className="CardContainer">
        {requiresPayment ? (
          <Checkout plan="starter" />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="CardContainerTitle">
              <h1>Event erstellen</h1>
            </div>
            <div>
              <h4>Event Titel:</h4>
              <input
                type="text"
                name="title"
                value={eventData.title}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>
            <div>
              <h4>Beschreibung:</h4>
              <textarea
                name="description"
                value={eventData.description}
                onChange={handleChange}
                required
                className="form-input"
              ></textarea>
            </div>
            <div>
              <h4>Ort:</h4>
              <input
                type="text"
                name="location"
                value={eventData.location}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>
            <div>
              <h4>Datum:</h4>
              <input
                type="date"
                name="date"
                value={eventData.date}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>
            <div>
              <h4>Beginn:</h4>
              <input
                type="time"
                name="time"
                value={eventData.time}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>
            <div>
              <h4>Maximale Anzahl von Bildern pro Gast:</h4>
              <select
                name="max_images_per_user"
                className="form-select"
                value={eventData.max_images_per_user}
                onChange={handleChange}
                required
              >
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </select>
            </div>
            <br />
            <button className="btn-gl" type="submit">
              Event erstellen
            </button>
          </form>
        )}
      </div>
  );
};

export default CreateEventForm;
