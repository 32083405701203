import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Checkout = ({ plan }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleCheckout = async () => {
        setLoading(true);
        setError(null);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ plan }),
        });

        const session = await response.json();

        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    return (
        <div>
            <button onClick={handleCheckout} disabled={loading}>
                {loading ? 'Lade...' : `Kaufe ${plan}`}
            </button>
            {error && <div>{error}</div>}
        </div>
    );
};

export default Checkout;
