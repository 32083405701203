import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";

function ManualInput() {
  const [manualSerialNumber, setManualSerialNumber] = useState("");
  const [manualKey, setManualKey] = useState("");
  const navigate = useNavigate();

  function handleManualSerialNumberChange(event) {
    setManualSerialNumber(event.target.value);
  }

  function handleManualKeyChange(event) {
    setManualKey(event.target.value);
  }

  function handleManualSubmit() {
    if (manualSerialNumber && manualKey) {
      // Navigiere zu /dashboard/gallery/:id mit der eingegebenen eventId und dem key
      navigate(`/dashboard/gallery/${manualSerialNumber}?key=${manualKey}`);
    }
  }

  return (
    <Layout>
      <div className="Top">
        <div className="CardContainerTop CardContainer21">
          <div className="CardContainer">
            <div className="CardContainerTitle">
              <h1>Event ID und Key Eingabe</h1>
            </div>
            <div>
              <p className="Top1">Event Id:</p>
              <div className="">
                <input
                  className="form-input"
                  type="text"
                  value={manualSerialNumber}
                  onChange={handleManualSerialNumberChange}
                />
              </div>
              <p className="">Event Key:</p>
              <div className="">
                <input
                  className="form-input"
                  type="text"
                  value={manualKey}
                  onChange={handleManualKeyChange}
                />
              </div>
              <div className="">
                <button className="btn-gl" onClick={handleManualSubmit}>
                  Öffnen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ManualInput;
