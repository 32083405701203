import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRolesByUserId } from "./../api/auth";
import { setWorker, setStaff, setCreator } from "./../redux/slices/rolesSlice";

const useUserRoles = () => {
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state.role);
  const { userId } = useSelector((state) => state.user);
  const { worker, staff, creator } = useSelector((state) => state.roles);
  const { companyId } = useSelector((state) => state.company); // Added companyId from Redux

  useEffect(() => {
    const fetchRoles = async () => {
      if (userId) {
        try {
          const rolesData = await getRolesByUserId(userId);
          if (rolesData.worker) {
            dispatch(setWorker({ id: rolesData.worker.id, role: rolesData.worker.role }));
          }
          if (rolesData.staff) {
            dispatch(setStaff({ id: rolesData.staff.id, role: rolesData.staff.role }));
          }
          if (rolesData.creator) {
            dispatch(setCreator({ id: rolesData.creator.id, role: rolesData.creator.role }));
          }
        } catch (error) {
          console.error("Error fetching roles:", error);
        }
      }
    };

    fetchRoles();
  }, [userId, dispatch]);

  // Funktion zum Überprüfen, ob die ID gültig ist
  const isValidId = (id) => id !== null && id !== undefined && !isNaN(id);

  // Rückgabe der Daten, einschließlich companyId und userRole
  return { userRole, worker, staff, creator, userId, companyId, isValidId };
};

export { useUserRoles };
