import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Layout from "./../../../components/layout";
import { toast } from "react-toastify"; 

const AddCreator = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { userId } = useSelector((state) => state.user);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Reset message

    if (!email) {
      toast.warn("Bitte geben Sie eine E-Mail-Adresse ein.");
      return;
    }

    try {
      // POST request to backend to add or update creator role
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/add-creator/${userId}`, // Backend endpoint
        { email } // Send the email in the body
      );

      if (response.data.success) {
        toast.success(`Erfolg: ${response.data.message}`);
      } else {
        toast.error(`Fehler: ${response.data.message}`);
      }
    } catch (error) {
      toast.error("Fehler beim Hinzufügen des Creators.");
    }
  };

  return (
    <Layout>
      <div className="Top">
        <div className="CardContainerTop">
          <div className="CardContainer">
            <div className="CardContainerTitle">
              <h2>Creator hinzufügen</h2>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <h4 htmlFor="email">Benutzer E-Mail:</h4>
                <input
                  type="email"
                  id="email"
                  className="form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Geben Sie die E-Mail-Adresse des Benutzers ein"
                  required
                />
              </div>
              <button type="submit" className="btn-gl">
                Creator hinzufügen
              </button>
            </form>

            {message && <p className="message">{message}</p>}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddCreator;
