import React from "react";
import "./datenschutz.css"; // Import der CSS-Datei
import Layout from "../components/layout";
import Footer from "./../components/Home/Footer/Footer";

// Die Datenschutz-Daten als JSON-Objekt
const datenschutz = [
  {
    id: "section1",
    title: "1. Verantwortliche Stelle",
    content:
      "Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne der Datenschutzgesetze ist: [Name des Unternehmens], [Adresse des Unternehmens], [Kontaktinformationen], [Datenschutzbeauftragter, falls vorhanden].",
  },
  {
    id: "section2",
    title: "2. Erhebung und Verarbeitung personenbezogener Daten",
    content:
      "Wir erheben und verarbeiten personenbezogene Daten, die erforderlich sind, um Ihnen unsere Dienste bereitzustellen. Dies umfasst insbesondere folgende Daten: Stammdaten (z. B. Name, Adresse, E-Mail-Adresse), Nutzungsdaten (z. B. Anmeldeinformationen, IP-Adresse, Log-Daten) und Zahlungsdaten (z. B. Bankverbindung, Kreditkartendaten).",
  },
  {
    id: "section3",
    title: "3. Zweck der Verarbeitung",
    content:
      "Die Erhebung und Verarbeitung Ihrer Daten erfolgt zu folgenden Zwecken: Zur Bereitstellung und Aufrechterhaltung unserer SaaS-Dienste, zur Verbesserung der Software, zur Abwicklung von Zahlungen und zur Erfüllung gesetzlicher Verpflichtungen.",
  },
  {
    id: "section4",
    title: "4. Weitergabe von Daten an Dritte",
    content:
      "Eine Weitergabe Ihrer personenbezogenen Daten an Dritte erfolgt nur, wenn dies für die Vertragsabwicklung notwendig ist, beispielsweise an Zahlungsdienstleister oder Hosting-Provider, oder wenn Sie ausdrücklich eingewilligt haben.",
  },
  {
    id: "section5",
    title: "5. Speicherdauer",
    content:
      "Ihre personenbezogenen Daten werden nur so lange gespeichert, wie dies für die Erfüllung der genannten Zwecke erforderlich ist oder wie es die gesetzlichen Aufbewahrungsfristen vorsehen.",
  },
  {
    id: "section6",
    title: "6. Ihre Rechte",
    content:
      "Sie haben das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und Widerspruch. Zur Ausübung dieser Rechte können Sie sich jederzeit an uns wenden.",
  },
  {
    id: "section7",
    title: "7. Datensicherheit",
    content:
      "Wir verwenden technische und organisatorische Maßnahmen, um Ihre Daten vor Manipulation, Verlust und unbefugtem Zugriff zu schützen.",
  },
  {
    id: "section8",
    title: "8. Änderungen der Datenschutzerklärung",
    content:
      "Wir behalten uns vor, diese Datenschutzerklärung anzupassen, um Änderungen der rechtlichen Rahmenbedingungen oder unserer Dienste zu berücksichtigen.",
  },
];

// Inhaltsverzeichnis-Komponente
const Toc = ({ items, onClick }) => {
  return (
    <div className="toc-container">
      <h2 className="toc-title">Inhaltsverzeichnis</h2>
      <ul className="toc-list">
        {items.map((item) => (
          <li
            key={item.id}
            onClick={() => onClick(item.id)}
            className="toc-list-item"
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

// Haupt-Datenschutz-Komponente
const DatenschutzComponent = () => {
  // Funktion, um zum jeweiligen Abschnitt zu springen
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Layout>
      <div className="Top">
        <div className="datenschutz-container">
          {/* Inhaltsverzeichnis */}
          <Toc items={datenschutz} onClick={scrollToSection} />

          {/* Datenschutz-Abschnitte */}
          <div className="datenschutz-content">
            {datenschutz.map((item) => (
              <div id={item.id} key={item.id} className="section-datenschutz">
                <h3 className="section-title">{item.title}</h3>
                <p className="section-content">{item.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default DatenschutzComponent;
