import React, { useState } from 'react';
import './faq.css';
import faqImage from './../../../images/faq2bg.png'; // Dein Bild

const faqData = [
  {
    question: "Wie funktioniert die Bilder-Upload-Funktion?",
    answer: "Besucher scannen einfach den QR-Code, den du auf deinem Event bereitstellst, und können dann direkt von ihrem Smartphone aus Bilder hochladen. Diese werden sofort auf deiner Plattform gespeichert und können per Mail direkt verschickt werden."
  },
  {
    question: "Sind die hochgeladenen Bilder sicher?",
    answer: "Ja, wir legen großen Wert auf die Sicherheit deiner Daten. Alle Bilder werden verschlüsselt übertragen und sicher auf unseren Servern gespeichert."
  },
  {
    question: "Kann ich die Bilder nach dem Event bearbeiten oder löschen?",
    answer: "Ja, als Event-Organisator hast du die volle Kontrolle über die hochgeladenen Bilder. Du kannst Bilder auch löschen, wenn notwendig."
  },
  {
    question: "Wie viele Bilder können hochgeladen werden?",
    answer: "Die anzahl der Bilder wird beim erstellen des Events festgelegt die pro Gast hochgeladen werden können. Unser Service ist darauf ausgelegt, eine große Menge an Bildern zu verarbeiten und zu speichern."
  },
  {
    question: "Welche Kosten fallen für die Nutzung an?",
    answer: "Wir bieten verschiedene Preismodelle an. Du kannst unsere Basisversion auf anfrage auch kostenlos Testen und bei Bedarf auf erweiterte Funktionen upgraden."
  },
  {
    question: "Kann ich das QR-Code an mein Event anpassen?",
    answer: "Jaein, wir bieten die Möglichkeit denn QR-Code einfach zu generieren und herunterzuladen. Wenn du spezielle Anforderungen hast, können wir dir auch einen individuellen QR-Code erstellen."
  },
];

const FAQComponent = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div id='faq' className="faq-container">
      {/* Linkes Bild */}
      <div className="faq-image">
        <img src={faqImage} alt="FAQ Bild" />
      </div>

      {/* Rechte FAQ Sektion */}
      <div className="faq-section">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleToggle(index)}>
              {item.question}
            </div>
            {openIndex === index && (
              <div className="faq-answer">
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;
