import React from "react";
import { FaUserEdit, FaUpload } from "react-icons/fa";
import Layout from "../../../components/layout";
import "./../../Dashboard/dashboard.css";
import { useUserRoles } from "./../../../hook/useUserRoles";

const Dashboard = () => {
  const {
    userRole,
    worker,
    staff,
    creator,
    userId,
    companyId,
    adminId,
    isValidId,
  } = useUserRoles();

  // Funktion zur Entscheidung, ob user oder company angezeigt wird
  const getPrimaryRole = () => {
    if (isValidId(companyId)) return "company"; // Zeige company, wenn es gültig ist
    if (userRole === "user") return "user";
    if (userRole === "admin") return "admin";
    return null;
  };

  const primaryRole = getPrimaryRole();

  // Überprüft, ob der Wert gültig ist und kein NaN-Wert
  const isValid = (value) => {
    return value !== undefined && value !== null && !isNaN(value);
  };

  const indexData = [
    {
      title: "Profil aktualisieren",
      icon: <FaUserEdit />,
      link: "/user/profile/data",
    },
    {
      title: "Profilbild aktualisieren",
      icon: <FaUpload />,
      link: "/user/profile/picture",
    },
  ];

  const DashboardCard = ({ title, icon, link }) => {
    const cardContent = (
      <div className="card">
        <div className="card-icon">{icon}</div>
        <div className="card-title">{title}</div>
      </div>
    );

    return link ? (
      <a href={link} className="card-link">
        {cardContent}
      </a>
    ) : (
      cardContent
    );
  };

  return (
    <Layout>
      <div className="Top">
        <div className="dashboard-container">
          <div className="CardContainer">
            <div className="CardContainerTitle">
              <h2>Benutzerprofil Info</h2>
            </div>
            <div className="CardContainer21">
              <p>
                Willkommen im Benutzerprofilbereich. Hier können Sie Ihr Profil
                aktualisieren und Ihr Profilbild hochladen.
              </p>
              <br />
              {/* Zeige die Benutzer-ID nur an, wenn sie gültig ist */}
              {isValid(userId) && <p>Benutzer-ID: {userId}</p>}
              
              {/* Zeige die Hauptrolle: entweder "user" oder "company" */}
              {primaryRole && <p>Rolle: {primaryRole}</p>}

              {/* Zeige die Unternehmens-ID nur an, wenn sie gültig ist */}
              {isValid(companyId) && <p>Unternehmens-ID: {companyId}</p>}

              {isValid(adminId) && <p>Admin-ID: {adminId}</p>}

              {/* Zeige vorhandene Rollen und ihre Daten */}
              {isValid(worker?.id) && (
                <div>
                  <p>Worker ID: {worker.id}</p>
                  <p>Worker Role: {worker.role}</p>
                </div>
              )}
              {isValid(staff?.id) && (
                <div>
                  <p>Staff ID: {staff.id}</p>
                  <p>Staff Role: {staff.role}</p>
                </div>
              )}
              {isValid(creator?.id) && (
                <div>
                  <p>Creator ID: {creator.id}</p>
                  <p>Creator Role: {creator.role}</p>
                </div>
              )}

              {/* Fallback, falls keine zusätzlichen Rollen vorhanden sind */}
              {!isValid(worker?.id) &&
                !isValid(staff?.id) &&
                !isValid(creator?.id) && (
                  <p>Keine zusätzlichen Rollen zugewiesen.</p>
                )}
            </div>
          </div>
          <div className="dashboard-grid CardContainerTop">
            {indexData.map((item, index) => (
              <DashboardCard
                key={index}
                title={item.title}
                icon={item.icon}
                link={item.link}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
