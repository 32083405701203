import React from "react";
import "./about.css"; // Neue CSS-Datei
import HZ1 from "./../../../images/hz00.png"; // Neues Bild

const MirroredComponent = () => {
  return (
    <>
      <div className="mirrored-background">
        <div className="mirrored-content">
          <div className="mirrored-text">
            <h1 className="mirrored-title wow-title">Dein Tool für sichere und einfache Eventbilder Verwaltung</h1>

            <div className="mirrored-features">
              <div className="mirrored-feature">
                <h3 className="wow-feature">Event erstellen</h3>
                <p>Erstelle einfach ein Event und lade deine Teilnehmer ein, sich zu registrieren und ihre Bilder hochzuladen.</p>
              </div>
              <div className="mirrored-feature">
                <h3 className="wow-feature">Kostenloses Bilder-Upload</h3>
                <p>Deine Teilnehmer können kostenlos und unkompliziert Bilder vom Event hochladen.</p>
              </div>
              <div className="mirrored-feature">
                <h3 className="wow-feature">Bild-Management</h3>
                <p>Du entscheidest, welche Bilder bleiben und welche nicht den Richtlinien entsprechen und gelöscht werden.</p>
              </div>
              <div className="mirrored-feature">
                <h3 className="wow-feature">Bilder per E-Mail teilen</h3>
                <p>Nach dem Event kannst du die schönsten Bilder ganz einfach an deine Teilnehmer per E-Mail verschicken.</p>
              </div>
            </div>
          </div>
          <div className="mirrored-image-container">
            <img className="mirrored-image wow-image" src={HZ1} alt="Beispielbild" />
          </div>
        </div>
      </div>
      <div className="gerade"></div>
    </>
  );
};

export default MirroredComponent;
