import React, { useState } from "react";
import { useSelector } from "react-redux";
import { uploadImage } from "../../../api/auth";
import Layout from "../../../components/layout";

const ImageUpload = () => {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const { userId } = useSelector((state) => state.user);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert("Bitte wählen Sie eine Datei aus");
      return;
    }

    try {
      const data = await uploadImage(file, userId);
      setImageUrl(data.imageUrl);
    } catch (error) {
      console.error("Fehler beim Hochladen des Bildes:", error);
    }
  };

  return (
    <Layout>
      <div className="Top">
        <div className="CardContainerTop">
          <div className="CardContainer">
            <div className="CardContainerTitle">
              <h2>Profilbild hochladen</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <input type="file" accept="image/*" onChange={handleFileChange} />
              <br />
              <br />
              <button className="btn-gl" type="submit">Bild hochladen</button>
            </form>
            {imageUrl && (
              <div>
                <p>
                  Bild erfolgreich hochgeladen:{" "}
                  <a href={imageUrl}>{imageUrl}</a>
                </p>
                <img src={imageUrl} alt="Hochgeladenes Bild" width="200" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ImageUpload;
