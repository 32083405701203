import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const TicketResponses = () => {
  const [tickets, setTickets] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState("");
  const [responses, setResponses] = useState([]);
  const [message, setMessage] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const userId = useSelector((state) => state.user.userId);

  // Fetch all tickets for the user
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/ticket/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setTickets(data);
        } else {
          setMessage(`Fehler: ${data.error}`);
        }
      } catch (error) {
        setMessage("Fehler beim Laden der Tickets.");
      }
    };

    if (userId) {
      fetchTickets();
    }
  }, [userId]);

  // Fetch ticket responses when a ticket is selected
  useEffect(() => {
    if (!selectedTicketId) return;

    const fetchResponses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/tickets/${selectedTicketId}/responses`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setResponses(data);
        } else {
          setMessage(`Fehler: ${data.error}`);
        }
      } catch (error) {
        setMessage("Fehler beim Laden der Antworten.");
      }
    };

    fetchResponses();
  }, [selectedTicketId]);

  // Handle adding a new response
  const handleAddResponse = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/tickets/${selectedTicketId}/responses`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message: newMessage, user_id: userId }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setResponses([...responses, data]);
        setNewMessage("");
      } else {
        setMessage(`Fehler: ${data.error}`);
      }
    } catch (error) {
      setMessage("Fehler beim Hinzufügen der Antwort.");
    }
  };

  return (
    <>
      <div className="Bottom1">
        <div className="CardContainerTop">
          <div className="CardContainer">
            <div className="CardContainerTitle">
              <h2>Ticket Antworten</h2>
            </div>

            {/* Dropdown for selecting a ticket */}
            <div>
              <label>Wählen Sie ein Ticket aus:</label>
              <select
                className="form-select"
                value={selectedTicketId}
                onChange={(e) => setSelectedTicketId(e.target.value)}
              >
                <option value="">-- Wählen Sie ein Ticket --</option>
                {tickets.map((ticket) => (
                  <option key={ticket.ticket_id} value={ticket.ticket_id}>
                    {ticket.title} (ID: {ticket.ticket_id})
                  </option>
                ))}
              </select>
            </div>

            {/* Show responses for the selected ticket */}
            {selectedTicketId && (
              <>
                <form>
                  <ul>
                    {responses.map((response) => (
                      <li key={response.response_id}>
                        <p>{response.message}</p>
                        <p>Geschrieben von: {response.user_id}</p>
                      </li>
                    ))}
                  </ul>
                </form>

                {/* Form to add a new response */}
                <form onSubmit={handleAddResponse}>
                  <div>
                    <label>Antworten:</label>
                    <textarea
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      required
                    />
                  </div>
                  <button type="submit">Antwort senden</button>
                </form>
              </>
            )}

            {message && <p>{message}</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketResponses;
