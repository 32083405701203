import { useState } from "react";
import { onLogin } from "../../../api/auth";
import { useDispatch } from "react-redux";
import { authenticateUser } from "../../../redux/slices/authSlice";
import { setCompany } from "../../../redux/slices/company";
import { setUser } from "../../../redux/slices/user";
import { setUserRole } from "../../../redux/slices/userRoleSlice";
import { findIdsByEmail } from "./../../../api/auth";
import { Link } from "react-router-dom";
import "./../signup.css";

const Login = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await onLogin(values);

      // Benutze die E-Mail aus dem Formular, um die IDs abzurufen
      const { userId, workerId, companyId } = await findIdsByEmail(
        values.email
      );

      // Speichere die IDs im Redux Store
      dispatch(setUserRole(response.data.role));
      dispatch(setUser(userId));
      dispatch(setCompany(companyId));
      dispatch(authenticateUser());

      localStorage.setItem("userRole", response.data.role);
      localStorage.setItem("isAuth", "true");
      localStorage.setItem("token", response.token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("companyId", companyId);
      localStorage.setItem("workerId", workerId);
    } catch (error) {
      console.log(error.response.data.errors[0].msg);
      setError(error.response.data.errors[0].msg);
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="container">
      <div className="Bottom">
        <div className="CardContainer">
          <form onSubmit={(e) => onSubmit(e)} className="">
            <div className="CardContainerTitle">
              <h2>Login</h2>
            </div>

            <div className="">
              <label htmlFor="email" className="form-label">
                Email adresse:
                <input
                  onChange={(e) => onChange(e)}
                  type="email"
                  className="form-input"
                  id="email"
                  name="email"
                  value={values.email}
                  placeholder="User@test.com"
                  required
                />
              </label>
            </div>

            <div className="">
              <label htmlFor="password" className="form-label">
                Passwort:
                <input
                  onChange={(e) => onChange(e)}
                  type="password"
                  value={values.password}
                  className="form-input"
                  id="password"
                  name="password"
                  placeholder="Passwort"
                  required
                />
              </label>
            </div>
            <div style={{ marginTop: "5px" }}>
              <Link to="/request-password-reset" className="">
                Passwort vergessen?
              </Link>
            </div>

            <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
            <br />
            <button type="submit" className="btn-gl">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
