import React, { useEffect, useState } from "react";
import axios from "axios";

function getEventIdFromUrl() {
  const path = window.location.pathname;
  const pathParts = path.split("/");
  const eventId = pathParts[pathParts.length - 1];
  return eventId;
}

function formatDate(dateString) {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("de-DE", options);
}

function formatTime(timeString) {
  const [hour, minute] = timeString.split(":");
  return `${hour}:${minute}`;
}

const EventDetails = () => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const eventId = getEventIdFromUrl();
  const key = new URLSearchParams(window.location.search).get("key"); // Key aus der URL extrahieren

  useEffect(() => {
    const fetchEvent = async () => {
      try {
  
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getevents/${eventId}/${key}`
        );
  
  
        if (response.data.status === "success") {
          const eventData = response.data.data.event;
  
          // Formatieren des Datums und der Uhrzeit
          eventData.date = formatDate(eventData.date);
          eventData.time = formatTime(eventData.time);
  
          setEvent(eventData);
        } else {
          setError("Error fetching event");
          console.error("API returned an error:", response.data.message);
        }
      } catch (err) {
        console.error("Fetch error:", err.response ? err.response.data : err.message);
        setError("An error occurred while fetching the event.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchEvent();
  }, [eventId, key]);
  

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!event) {
    return <p>No event found</p>;
  }

  return (
    <div className="Top">
      <div className="CardContainerTop">
        <div className="CardContainer CardContainer21">
          <form>
            <div className="CardContainerTitle">
              <h2>{event.title}</h2>
            </div>
            <p>Veranstaltung: {event.description}</p>
            <p>Ort: {event.location}</p>
            <br/>
            <p>Datum: {event.date}</p>
            <p>Uhrzeit: {event.time}</p>
            <br/>
            <p>Event-ID: {eventId}</p>
            <p>Key: {key}</p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
