import { createSlice } from "@reduxjs/toolkit";

const userIdFromLocalStorage = () => {
  const userId = localStorage.getItem("userId");
  return userId ? parseInt(userId) : "";
};

const initialUserState = {
  userId: userIdFromLocalStorage(),
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
