// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  /* Icon in der Kamera-Bedienoberfläche */
#reader button, 
#reader i, 
#reader svg {
  color: #00ffff; /* Setzt die Icon-Farbe auf #00ffff */
  fill: #00ffff; /* Für SVG-Icons */
}

/* Falls das Icon ein img-Tag ist */
#reader img {
  filter: invert(75%) sepia(94%) saturate(2259%) hue-rotate(157deg) brightness(107%) contrast(102%);
  /* Diese CSS-Filter erzeugen eine Farbe ähnlich zu #00ffff */
}

#reader span {
  color: #00ffff; /* Setzt die Textfarbe auf #00ffff */
}

#reader p {
  color: #00ffff; /* Setzt die Textfarbe auf #00ffff */
}
`, "",{"version":3,"sources":["webpack://./src/private/dashboardcomponents/events/qrcode.css"],"names":[],"mappings":";;EAEE,wCAAwC;AAC1C;;;EAGE,cAAc,EAAE,qCAAqC;EACrD,aAAa,EAAE,kBAAkB;AACnC;;AAEA,mCAAmC;AACnC;EACE,iGAAiG;EACjG,4DAA4D;AAC9D;;AAEA;EACE,cAAc,EAAE,oCAAoC;AACtD;;AAEA;EACE,cAAc,EAAE,oCAAoC;AACtD","sourcesContent":["\r\n  \r\n  /* Icon in der Kamera-Bedienoberfläche */\r\n#reader button, \r\n#reader i, \r\n#reader svg {\r\n  color: #00ffff; /* Setzt die Icon-Farbe auf #00ffff */\r\n  fill: #00ffff; /* Für SVG-Icons */\r\n}\r\n\r\n/* Falls das Icon ein img-Tag ist */\r\n#reader img {\r\n  filter: invert(75%) sepia(94%) saturate(2259%) hue-rotate(157deg) brightness(107%) contrast(102%);\r\n  /* Diese CSS-Filter erzeugen eine Farbe ähnlich zu #00ffff */\r\n}\r\n\r\n#reader span {\r\n  color: #00ffff; /* Setzt die Textfarbe auf #00ffff */\r\n}\r\n\r\n#reader p {\r\n  color: #00ffff; /* Setzt die Textfarbe auf #00ffff */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
