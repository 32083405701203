import React from 'react'
import Layout from '../components/layout'

const service = () => {
  return (
    <Layout>
      <div>service</div>
    </Layout>
  )
}

export default service