import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Layout from "../components/layout";
import { useSelector } from "react-redux";
import Joyride from "react-joyride"; // Joyride importieren
import "./preise.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Checkout = ({ plan }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { companyId } = useSelector((state) => state.company);

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ plan }),
        }
      );

      const session = await response.json();

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        setError(error.message);
      }
    } catch (err) {
      setError("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {companyId ? (
        <p className="message">
          Sie haben bereits ein Unternehmen. Ein Kauf ist nicht mehr nötig.
        </p>
      ) : (
        <button
          className="btn-gl checkout-button"
          onClick={handleCheckout}
          disabled={loading}
        >
          {loading ? "Lade..." : `${plan} Paket kaufen`}
        </button>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

const PricingPlans = () => {
  // Joyride steps definition
  const steps = [
    {
      target: "#pricing-title", // ID des Titels als Ziel
      content: "Hier können Sie zwischen verschiedenen Paketen wählen. *Bitte geben Sie bei der Buchung ihre Login-Email an, damit wir das Paket Ihrem Account zuordnen können.",
    },
  ];

  // Joyride state
  const [tourState, setTourState] = useState({ run: false });

  // Überprüfen, ob der Benutzer die Tour bereits gesehen hat
  useEffect(() => {
    const hasSeenTour = localStorage.getItem("hasSeenTourPricingPlans");

    // Wenn die Tour nicht gesehen wurde, starten
    if (!hasSeenTour) {
      setTourState({ run: true });
    }
  }, []);

  // Callback für Joyride
  const handleJoyrideCallback = (data) => {
    const { status, action } = data;

    // Tour beenden, wenn der Benutzer sie schließt oder abschließt
    if (status === "finished" || action === "close") {
      setTourState({ run: false });
      localStorage.setItem("hasSeenTourPricingPlans", "true"); // Markiere als gesehen
    }
  };

  return (
    <Layout>
      <Joyride
        steps={steps}
        run={tourState.run}
        continuous
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="Bottom1 Top">
        <div className="pricing-section">
          <div className="pricing-header" id="pricing-header">
            <div className="CardContainer">
              <div className="CardContainerTitle">
                <h1 id="pricing-title">Unsere Pakete</h1> {/* ID für Joyride */}
              </div>
              <p>
                Wählen Sie das Paket, das am besten zu Ihnen passt. Sie können
                jederzeit upgraden oder ändern.
              </p>
            </div>
          </div>
          <br />
          <div className="pricing-cards">
            {/* Starter Paket */}
            <div className="pricing-card">
              <h2 className="plan-title">Starter Paket</h2>
              <p className="plan-description">
                Ideal für Einsteiger, die ein einzelnes Event organisieren
                möchten.
              </p>
              <div className="price-info">
                <span className="old-price">50€</span>
                <span className="new-price">24,45€</span>
              </div>
              <ul className="plan-features">
                <li>Einmaliges Event-Management</li>
                <li>Basic Support</li>
                <li>1 Event</li>
              </ul>
              <Checkout plan="Starter" />
            </div>

            {/* Pro Paket */}
            <div className="pricing-card pro-plan">
              <h2 className="plan-title">Pro Paket</h2>
              <p className="plan-description">
                Perfekt für Unternehmen, die regelmäßig Events organisieren und
                erweiterten Support benötigen.
              </p>
              <div className="price-info">
                <span className="old-price">200€/mtl</span>
                <span className="new-price">144,95€/mtl</span>
              </div>
              <ul className="plan-features">
                <li>Unbegrenzte Events</li>
                <li>Erweiterter Support</li>
                <li>Mitarbeiter-Management</li>
                <li>Beta Features </li>
              </ul>
              <Checkout plan="Pro" />
            </div>

            {/* Whitelabel Paket */}
            <div className="pricing-card whitelabel-plan">
              <h2 className="plan-title">Whitelabel Lösung</h2>
              <p className="plan-description">
                Ihre eigene Event-Management Software mit Ihrem Branding, Logo
                und Design.
              </p>
              <ul className="plan-features">
                <li>Individuelles Branding</li>
                <li>Eigene Domain</li>
                <li>Custom Features und Support</li>
              </ul>
              <a
                href="mailto:info@q-tech.dev?subject=Anfrage%20zur%20Whitelabel%20Lösung&body=Bitte%20geben%20Sie%20uns%20mehr%20Informationen%20zur%20Whitelabel-Lösung."
                className="btn-gl contact-button"
              >
                Kontaktieren Sie uns
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricingPlans;
