// authService.js
import { fetchProtectedInfo, onLogout } from "../api/auth";
import { unauthenticateUser } from "../redux/slices/authSlice";

export const logout = async (dispatch) => {
  try {
    await onLogout();
    dispatch(unauthenticateUser());
    localStorage.removeItem("isAuth");
  } catch (error) {
    console.log(error.response);
  }
};

export const getProtectedInfo = async (dispatch, setProtectedData, setLoading) => {
  try {
    const { data } = await fetchProtectedInfo();
    setProtectedData(data.info);
    setLoading(false);
  } catch (error) {
    await logout(dispatch);
  }
};
