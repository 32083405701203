import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { verifyOtp, resendOtp } from "../../../api/auth";
import Confetti from "react-confetti";
import "./../signup.css";
import Layout from "../../layout";

const OtpVerification = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentOtpToken, setCurrentOtpToken] = useState("");

  useEffect(() => {
    const email = localStorage.getItem("email");
    const otpToken = localStorage.getItem("otpToken");

    if (email && otpToken) {
      setCurrentEmail(email);
      setCurrentOtpToken(otpToken);
    } else {
      setError("Fehler: Keine Verifikationsinformationen gefunden.");
    }
  }, []);

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    try {
      const { data } = await verifyOtp({ otpToken: currentOtpToken, otp });
      setSuccess(data.message);
      setError("");
      setShowConfetti(true);

      setTimeout(() => {
        setShowConfetti(false);
        navigate("/signup");
      }, 5000);
    } catch (error) {
      setError("Ungültiger Token.");
      setSuccess("");
    }
  };

  const handleResendOtp = async () => {
    try {
      const { data } = await resendOtp({ email: currentEmail });
      setCurrentOtpToken(data.otpToken); // Update the currentOtpToken with the new one
      localStorage.setItem("otpToken", data.otpToken); // Save the new otpToken to localStorage
      setSuccess("Token wurde erneut gesendet.");
      setError("");
    } catch (error) {
      console.error("Fehler beim erneuten Senden des Tokens:", error);
      setError("Fehler beim erneuten Senden des Tokens.");
      setSuccess("");
    }
  };

  return (
    <Layout>
      <section className="Top">
        <div className="CardContainerTop">
          <div className="CardContainer">
            {showConfetti && <Confetti />}
            <div className="CardContainerTitle">
              <h2>Email-Adresse Verifizieren</h2>
            </div>
            <form onSubmit={handleVerifyOtp}>
              <label htmlFor="otp" className="form-label">
                Verifizierung:
                <input
                  onChange={handleOtpChange}
                  type="text"
                  className="form-input"
                  id="otp"
                  name="otp"
                  value={otp}
                  placeholder="Token eingeben"
                  required
                />
              </label>
              <NavLink type="button" onClick={handleResendOtp} className="">
                Erneut senden
              </NavLink>
              <br />
              <br />
              <button type="submit" className="btn-gl">
                Bestätigen
              </button>
              <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
              <div style={{ color: "green", margin: "10px 0" }}>{success}</div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OtpVerification;
