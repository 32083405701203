import React, { useEffect, useState } from "react";
import "./text.css";

const TextComponent = () => {
  const [isStraight, setIsStraight] = useState(false);
  const [isShining, setIsShining] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".hero-text-one");
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Auf kleinen Bildschirmen
      if (window.innerWidth <= 767) {
        // Check, ob das Element zu 100% sichtbar ist
        if (rect.top >= 0 && rect.bottom <= windowHeight) {
          // Element ist 100% sichtbar, Shine-Effekt und Gerade aktivieren
          setIsStraight(true);
          setIsShining(true);
        }
        // Check, ob das Element nur noch zu 50% sichtbar ist
        else if (rect.top < windowHeight / 2 || rect.bottom > windowHeight) {
          // Element ist nur noch zur Hälfte sichtbar, zurück zum schrägen Zustand
          setIsStraight(false);
          setIsShining(false);
        }
      }
    };

    // Initialen Zustand bei Seite laden (normales schräges Layout)
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-text">
      <div
        className={`hero-text-one ${isStraight ? "scroll-straight" : ""} ${
          isShining ? "scroll-active" : ""
        }`}
      >
        <h4>
          Erstelle und verwalte Events, lass deine Gäste bilder hochladen und
          organisiere ihre hochgeladenen Bilder – alles an einem Ort
        </h4>
      </div>
    </div>
  );
};

export default TextComponent;
