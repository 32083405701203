//addworker.js
import React, { useState } from "react";
import { useSelector } from "react-redux";

const SetUserRole = () => {
  const [inputValue, setInputValue] = useState("");
  const [message, setMessage] = useState("");
  const { companyId } = useSelector((state) => state.company);

  const handleSetRole = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!companyId) {
      setMessage("Company ID is missing.");
      return;
    }

    // Check if input is an email or user ID
    const isEmail = /\S+@\S+\.\S+/.test(inputValue); // Simple regex to check if input is an email

    const url = isEmail
      ? `${process.env.REACT_APP_API_URL}/api/set-role/email/${companyId}`
      : `${process.env.REACT_APP_API_URL}/api/set-role/id/${companyId}`;

    const body = isEmail ? { email: inputValue } : { user_id: inputValue };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      if (response.ok) {
        if (isEmail && data.user && data.user.email) {
          // Handle the case for email
          setMessage(`Mitarbeiter erfolgreich hinzugefügt mit der Email: ${data.user.email}`);
        } else if (!isEmail && data.worker && data.worker.user_id) {
          // Handle the case for user ID
          setMessage(`Mitarbeiter hinzugefügt mit der User Id ${data.worker.user_id}`);
        } else {
          // If the response is not as expected
          setMessage("Mitabeiter erfolgreich aktualisiert.");
        }
      } else {
        setMessage(data.error || "Error updating role.");
      }
    } catch (error) {
      setMessage("Server error.");
    }
  };

  return (
    <div className="CardContainerTop">
      <div className="CardContainer">
        <form onSubmit={handleSetRole}>
          <div className="CardContainerTitle">
            <h2>Mitarbeiter hinzufügen</h2>
          </div>

          <div>
            <p>Email oder Benutzer-ID:</p>
            <input
              className="form-input"
              type="text"
              placeholder="Gib die Benutzer E-Mail oder ID ein"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button className="btn-gl" type="submit">
              Hinzufügen
            </button>
          </div>
        </form>

        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default SetUserRole;
