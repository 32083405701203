import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  FaCalendarAlt,
  FaCheckCircle,
  FaNewspaper,
  FaUnlock,
  FaUsers,
  FaImages,
  FaPlus,
} from "react-icons/fa";
import { BiCalendarStar } from "react-icons/bi";
import Layout from "./../../components/layout";
import Joyride from "react-joyride";
import "./dashboard.css";

// indexData für die Dashboard-Karten mit Titel, Icon, Link und Beschreibung
const indexData = [
  {
    title: "Freischalten",
    icon: <FaUnlock />,
    link: "/preisliste",
    role: ["user", "worker", "staff", "creator", "admin"],
    description: "Schalten Sie hier alle Premium-Funktionen frei.",
    target: "card-unlock",
  },
  {
    title: "Event erstellen",
    icon: <BiCalendarStar />,
    link: "/dashboard/event",
    role: ["all"],
    description: "Erstellen Sie ein neues Event nach dem Sie die Funktionen freigeschaltet haben.",
    target: "card-create-event",
  },
  {
    title: "Event Aktivieren",
    icon: <FaUsers />,
    link: "/dashboard/event/qrgenerator",
    role: ["company", "creator", "admin"],
    description: "Aktivieren Sie Ihr Event und generieren Sie QR-Codes.",
    target: "card-activate-event",
  },
  {
    title: "Mitarbeiter verwalten",
    icon: <FaUsers />,
    link: "/dashboard/worker",
    role: ["company", "admin"],
    description: "Verwalten Sie Ihre Mitarbeiter und fügen Sie neue hinzu.",
    target: "card-manage-workers",
  },
  {
    title: "Personal verwalten",
    icon: <FaUsers />,
    link: "/dashboard/staff",
    role: ["creator", "admin"],
    description: "Verwalten Sie Ihr Personal und deren Rollen.",
    target: "card-manage-staff",
  },
  {
    title: "Galerie bearbeiten",
    icon: <FaImages />,
    link: "/dashboard/gallery",
    role: ["company", "creator", "worker", "staff", "admin"],
    description: "Bearbeiten Sie Ihre Eventgalerie.",
    target: "card-manage-gallery",
  },
  {
    title: "Anstehende Events",
    icon: <FaCalendarAlt />,
    link: "/user/events",
    role: ["admin", "company", "staff", "worker", "creator", "admin"],
    description: "Sehen Sie sich Ihre anstehenden Events an.",
    target: "card-upcoming-events",
  },
  {
    title: "Teilgenommene Events",
    icon: <FaCheckCircle />,
    link: "/user/abgeschlossen",
    role: ["all"],
    description: "Überprüfen Sie die abgeschlossenen und früheren Events.",
    target: "card-past-events",
  },
  {
    title: "New & Updates",
    icon: <FaNewspaper />,
    link: "/user/news",
    role: ["all"],
    description: "Lesen Sie die neuesten Nachrichten und Updates.",
    target: "card-news-updates",
  },
  {
    title: "Creator Plus",
    icon: <FaPlus />,
    link: "/admin/limit",
    role: ["admin"],
    description: "Verwalten Sie die Creator Plus Funktionen.",
    target: "card-creator-plus",
  },
  {
    title: "Nachricht an Support",
    icon: <FaNewspaper />,
    link: "/dashboard/ticket",
    role: ["all"],
    description: "Verwalten Sie Ihre Tickets.",
    target: "card-tickets",
  },
];

// Component to render each card
const DashboardCard = ({ title, icon, link, description, target }) => {
  return (
    <a href={link} className="card-link">
      <div className={`card ${target}`} id={target}>

        <div className="card-icon">{icon}</div>

        <div className="card-title">{title}</div>
        <div className="card-description">{description}</div>
      </div>
    </a>
  );
};

// Main Dashboard component
const Dashboard = () => {
  // Fetch the main role from userRoleSlice
  const { userRole } = useSelector((state) => state.role);

  // Fetch additional roles (staff, worker, creator) from rolesSlice
  const { worker, staff, creator } = useSelector((state) => state.roles);

  // Extract role values from worker, staff, and creator objects
  const workerRole = worker?.role || null;
  const staffRole = staff?.role || null;
  const creatorRole = creator?.role || null;

  // Combine the userRole with the additional roles into a single array
  const currentRoles = [userRole, workerRole, staffRole, creatorRole].filter(Boolean);

  // Define Joyride steps based on indexData
  const steps = [
    {
      target: "#dashboard-title",
      content: "Willkommen im Dashboard! Hier findest du alle wichtigen Funktionen.",
    },
    ...indexData.map((item) => ({
      target: `#${item.target}`,
      content: `${item.description}`, // Beschreibungen aus indexData in den Joyride-Schritten verwenden
    })),
  ];

  // Joyride state
  const [tourState, setTourState] = useState({
    run: false, // Standardmäßig nicht ausgeführt
  });

  // Check if the user has seen the tour before
  useEffect(() => {
    const hasSeenTour = localStorage.getItem("hasSeenTour");

    // If the user hasn't seen the tour, start it
    if (!hasSeenTour) {
      setTourState({ run: true });
    }
  }, []);

  // Callback function for Joyride
  const handleJoyrideCallback = (data) => {
    const { status, action } = data;

    // Stop the tour if the user closes it or completes it
    if (status === "finished" || action === "close") {
      setTourState({ run: false });
      localStorage.setItem("hasSeenTour", "true"); // Mark as seen
    }
  };

  return (
    <Layout>
      {/* Joyride component */}
      <Joyride
        steps={steps}
        run={tourState.run}
        continuous
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        callback={handleJoyrideCallback}
      />

      {/* Dashboard content */}
      <div className="Bottom1">
        <div className="">
          <div className="Top">
            <div className="dashboard-container">
              <div className="CardContainer">
                <div className="CardContainterTile CardContainer21">
                  <h1 id="dashboard-title">Dashboard</h1>
                  <br />
                  <p>Im Dashboard. Hier finden Sie alle Funktionen, die Ihnen zur Verfügung stehen.</p>
                </div>
              </div>
              <br />
              <div className="dashboard-grid">
                {indexData
                  .filter((item) => {
                    // Show cards where the role matches the user's roles or is available to all roles
                    return (
                      item.role.includes("all") || // If available to all roles
                      item.role.some((role) => currentRoles.includes(role)) // Or if any of the user's roles match
                    );
                  })
                  .map((item, index) => (
                    <DashboardCard
                      key={index}
                      title={item.title}
                      icon={item.icon}
                      link={item.link}
                      description={item.description}
                      target={item.target}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
