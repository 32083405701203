import Layout from "../components/layout";
import Signup from "../components/Signup/signup";

const signup = () => {
  return (
    <Layout>
      <Signup />
    </Layout>
  );
};

export default signup;
