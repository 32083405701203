import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { useSelector } from "react-redux";
import axios from "axios";
import "./picture.css";

function getEventIdFromUrl() {
  const path = window.location.pathname;
  const pathParts = path.split("/");
  const eventId = pathParts[pathParts.length - 1];
  return eventId;
}

const CameraComponent = () => {
  const webcamRef = useRef(null);
  const [images, setImages] = useState([]);
  const [cameraActive, setCameraActive] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const { userId } = useSelector((state) => state.user);
  const eventId = getEventIdFromUrl();
  const key = new URLSearchParams(window.location.search).get("key"); // Key aus der URL extrahieren

  const [isValidEvent, setIsValidEvent] = useState(false);
  const [error, setError] = useState(null);
  const [imageLimit, setImageLimit] = useState(10); // Standardmäßig 10, bis wir den Wert laden
  const navigate = useNavigate();

  // Sicherheitsmaßnahme: Überprüfen, ob Event-ID und Key gültig sind und das Bildlimit abrufen
  useEffect(() => {
    const validateEvent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getevents/${eventId}/${key}`
        );
        if (response.data.status === "success") {
          setIsValidEvent(true); // Wenn gültig, setzen wir isValidEvent auf true
          setImageLimit(response.data.data.event.max_images_per_user); // Bildlimit aus der Antwort
        } else {
          setError("Ungültige Event-ID oder Key.");
          setTimeout(() => {
            navigate("/home");
          }, 3000);
        }
      } catch (err) {
        setError("Fehler bei der Validierung des Events.");
        setTimeout(() => {
          navigate("/home");
        }, 3000);
      }
    };

    validateEvent();
  }, [eventId, key, navigate]);

  const capture = () => {
    if (images.length < imageLimit) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImages([...images, { src: imageSrc, file: null }]);
    } else {
      setUploadStatus(`Maximale Anzahl von ${imageLimit} Bildern erreicht.`);
    }
  };

  const deleteImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + images.length > imageLimit) {
      setUploadStatus(`Maximale Anzahl von ${imageLimit} Bildern erreicht.`);
      return;
    }

    const newImages = files.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve({ src: reader.result, file });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(newImages).then((imageData) => {
      setImages((prevImages) => [...prevImages, ...imageData]);
    });
  };

  const uploadImages = async () => {
    setUploadStatus("Uploading...");

    try {
      const formData = new FormData();

      for (let i = 0; i < images.length; i++) {
        const { file, src } = images[i];
        let blob;
        let fileName;

        if (file) {
          blob = file;
          fileName = file.name;
        } else {
          const byteString = atob(src.split(",")[1]);
          const mimeString = src.split(",")[0].split(":")[1].split(";")[0];
          const extension = mimeString.split("/")[1];

          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let j = 0; j < byteString.length; j++) {
            ia[j] = byteString.charCodeAt(j);
          }
          blob = new Blob([ab], { type: mimeString });
          fileName = `${Date.now()}-${i}.${extension}`;
        }

        formData.append("images", blob, fileName);
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upload/${eventId}/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setUploadStatus("Upload erfolgreich!");
      setImages([]);
    } catch (error) {
      console.error("Fehler beim Hochladen der Bilder:", error);
      setUploadStatus("Fehler beim Hochladen.");
    }
  };

  // Wenn Event ungültig ist oder es einen Fehler gibt, zeige eine Fehlermeldung an und leite weiter
  if (error) {
    return (
      <div className="Top CardContainer21">
        <div className="CardContainer">
        <p>{error}</p>
        <p>Du wirst in Kürze zur Startseite weitergeleitet...</p>
        </div>
      </div>
    );
  }

  // Ladeanzeige oder warte, bis das Event validiert ist
  if (!isValidEvent) {
    return <p>Überprüfung der Event-ID und des Schlüssels...</p>;
  }

  return (
    <div className="">
      <div className="CardContainer CardContainer21">
        <div className="Top21">
          <button className="btn-gl" onClick={() => setShowCamera(!showCamera)}>
            Kamera
          </button>
          <br/>

          {showCamera && (
            <div className="CameraWrapper">
              {cameraActive ? (
                <>
                  <div className="WebcamContainer">
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/png"
                      className="Webcam"
                    />
                  </div>
                  <button
                    className="btn-gl"
                    onClick={capture}
                    disabled={images.length >= imageLimit}
                  >
                    Aufnehmen
                  </button>
                  <br />
                  <br />
                </>
              ) : (
                <button
                  className="btn-gl"
                  onClick={() => setCameraActive(true)}
                >
                  Kamera Öffnen
                </button>
              )}
            </div>
          )}
          <br />
          <p>Oder ein vorhandenes Bild hinzufügen (bis zu {imageLimit} Bilder)</p>
          <br />
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            disabled={uploadStatus === "Uploading..." || images.length >= imageLimit}
          />

          {images.length > 0 && (
            <button className="btn-gl" onClick={uploadImages}>
              Upload {images.length} Bilder
            </button>
          )}

          {uploadStatus && <p>{uploadStatus}</p>}
        </div>

        <div className="ImagePreview">
          {images.map((image, index) => (
            <div
              key={index}
              className="ImageWrapper"
              onClick={() => setSelectedImage(image.src)}
            >
              <img
                src={image.src}
                alt={`Captured ${index}`}
                className="Thumbnail"
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  deleteImage(index);
                }}
                className="DeleteButton"
              >
                X
              </button>
            </div>
          ))}
        </div>

        {selectedImage && (
          <div onClick={() => setSelectedImage(null)} className="Modal">
            <img src={selectedImage} alt="Selected" className="ModalImage" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CameraComponent;
