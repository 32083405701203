import { createSlice } from "@reduxjs/toolkit";

const companyIdFromLocalStorage = () => {
  const companyId = localStorage.getItem("companyId");
  return companyId ? parseInt(companyId) : "";
};


const initialCompanyState = {
  companyId: companyIdFromLocalStorage(),
};

export const companySlice = createSlice({
  name: "company",
  initialState: initialCompanyState,
  reducers: {
    setCompany: (state, action) => {
      state.companyId = action.payload;
    },
  },
});

export const { setCompany } = companySlice.actions;

export const companyReducer = companySlice.reducer;
