import React, { useState } from 'react';
import './faq.css';
import faqImage from './../../../images/faq.png'; // Dein Bild

const faqData = [
  {
    question: "Wie funktioniert die Bilder-Upload-Funktion?",
    answer: "Besucher scannen einfach den QR-Code, den du auf deinem Event bereitstellst, und können dann direkt von ihrem Smartphone aus Bilder hochladen. Diese werden sofort auf deiner Plattform gespeichert und können mit allen anderen Besuchern geteilt werden."
  },
  {
    question: "Sind die hochgeladenen Bilder sicher?",
    answer: "Ja, wir legen großen Wert auf die Sicherheit deiner Daten. Alle Bilder werden verschlüsselt übertragen und sicher auf unseren Servern gespeichert."
  },
  {
    question: "Kann ich die Bilder nach dem Event bearbeiten oder löschen?",
    answer: "Ja, als Event-Organisator hast du die volle Kontrolle über die hochgeladenen Bilder. Du kannst Bilder bearbeiten, sortieren und auch löschen, wenn notwendig."
  },
  {
    question: "Wie viele Bilder können hochgeladen werden?",
    answer: "Es gibt keine Begrenzung für die Anzahl der hochgeladenen Bilder. Unser Service ist darauf ausgelegt, eine große Menge an Bildern zu verarbeiten und zu speichern."
  },
  {
    question: "Welche Kosten fallen für die Nutzung an?",
    answer: "Wir bieten verschiedene Preismodelle an, die auf die Bedürfnisse deines Events abgestimmt sind. Du kannst unsere Basisversion kostenlos testen und bei Bedarf auf erweiterte Funktionen upgraden."
  },
  {
    question: "Kann ich das Design der Plattform an mein Event anpassen?",
    answer: "Ja, du kannst das Erscheinungsbild der Plattform an dein Event-Branding anpassen, einschließlich Farben, Logos und Schriftarten."
  }
];

const FAQComponent = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      {/* Linkes Bild */}
      <div className="faq-image">
        <img src={faqImage} alt="FAQ Bild" />
      </div>

      {/* Rechte FAQ Sektion */}
      <div className="faq-section">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleToggle(index)}>
              {item.question}
            </div>
            {openIndex === index && (
              <div className="faq-answer">
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;
