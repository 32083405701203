import React from "react";
import "./agb.css"; // Import der CSS-Datei
import Layout from "../components/layout";
import Footer from "./../components/Home/Footer/Footer";

// Die AGB-Daten als JSON-Objekt
const agbs = [
  {
    id: "section1",
    title: "1. Geltungsbereich",
    content:
      "Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für die Nutzung der [Ihr Firmenname] Plattform zur Digitalisierung und zum Hochladen von Bildern von Events durch Veranstalter und deren Gäste. Mit der Nutzung der Plattform erklären Sie sich mit diesen AGB einverstanden.",
  },
  {
    id: "section2",
    title: "2. Leistungsbeschreibung",
    content:
      "[Ihr Firmenname] bietet eine Software-as-a-Service-Plattform (SaaS) an, die es Benutzern ermöglicht, Bilder von Events zu digitalisieren, indem Gäste QR-Codes scannen und ihre Fotos hochladen können. Die Plattform bietet verschiedene Preismodelle, die im Folgenden näher beschrieben werden.",
  },
  {
    id: "section3",
    title: "3. Preismodelle und Zahlungsbedingungen",
    content:
      "Beschreibung der verschiedenen Preismodelle, die Nutzungsmöglichkeiten und die Bedingungen zur Zahlung.",
  },
  {
    id: "section4",
    title: "4. Zahlungsabwicklung",
    content:
      "Die Zahlungsabwicklung erfolgt über den Zahlungsdienstleister Stripe, Inc. (nachfolgend „Stripe“). Mit der Nutzung unserer Plattform erklären Sie sich mit den Geschäftsbedingungen und der Datenschutzerklärung von Stripe einverstanden, die unter [Stripe Geschäftsbedingungen Link] und [Stripe Datenschutzbestimmungen Link] abrufbar sind.",
  },
  {
    id: "section5",
    title: "5. Ausschluss des Rückgaberechts",
    content:
      "Für einmalige Zahlungen, wie sie beim Einmaligen Event-Paket oder der Setup-Gebühr des Whitelabel-Pakets anfallen, besteht kein Rückgaberecht. Der Betrag wird einmalig abgebucht und eine Rückerstattung ist ausgeschlossen.",
  },
  {
    id: "section6",
    title: "6. Verantwortlichkeiten des Kunden",
    content:
      "Der Kunde ist für die Einhaltung aller geltenden Gesetze und Vorschriften bei der Nutzung der Plattform verantwortlich. Der Kunde ist dafür verantwortlich, sicherzustellen, dass die hochgeladenen Bilder keine Rechte Dritter verletzen.",
  },
  {
    id: "section7",
    title: "7. Nutzung von Google Analytics",
    content:
      "Unsere Website verwendet Google Analytics, einen Webanalysedienst der Google LLC („Google“). Google Analytics verwendet Cookies, die eine Analyse der Nutzung unserer Website ermöglichen.",
  },
  {
    id: "section8",
    title: "8. Datenverschlüsselung und Sicherheit",
    content:
      "Alle personenbezogenen Daten, die über die [Ihr Firmenname] Plattform erhoben und gespeichert werden, sind in unserer Datenbank durch moderne Verschlüsselungsverfahren geschützt.",
  },
  {
    id: "section9",
    title: "9. Verfügbarkeit und Haftung",
    content:
      "[Ihr Firmenname] bemüht sich um eine hohe Verfügbarkeit der Plattform, kann jedoch keine ununterbrochene Verfügbarkeit garantieren.",
  },
  {
    id: "section10",
    title: "10. Vertragsdauer und Kündigung",
    content:
      "Die Vertragslaufzeit richtet sich nach dem gewählten Preismodell. Das Unternehmens- und das Whitelabel-Paket können mit einer Frist von 30 Tagen zum Monatsende gekündigt werden.",
  },
  {
    id: "section11",
    title: "11. Datenschutz",
    content:
      "[Ihr Firmenname] verarbeitet personenbezogene Daten gemäß den geltenden Datenschutzbestimmungen. Weitere Informationen entnehmen Sie bitte unserer Datenschutzerklärung.",
  },
  {
    id: "section12",
    title: "12. Schlussbestimmungen",
    content:
      "Es gilt das Recht der [Ihr Land]. Sollten einzelne Bestimmungen dieser AGB unwirksam sein, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.",
  },
];

// Inhaltsverzeichnis-Komponente
const Toc = ({ items, onClick }) => {
  return (
    <div className="toc-container">
      <h2 className="toc-title">Inhaltsverzeichnis</h2>
      <ul className="toc-list">
        {items.map((item) => (
          <li
            key={item.id}
            onClick={() => onClick(item.id)}
            className="toc-list-item"
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

// Haupt-AGB-Komponente
const AgbComponent = () => {
  // Funktion, um zum jeweiligen Abschnitt zu springen
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Layout>
      <div className="Top">
        <div className="">
          <div className="agb-container">
            {/* Inhaltsverzeichnis */}
            <Toc items={agbs} onClick={scrollToSection} />

            {/* AGB-Abschnitte */}
            <div className="agb-content">
              {agbs.map((item) => (
                <div id={item.id} key={item.id} className="section-agb">
                  <h3 className="section-title">{item.title}</h3>
                  <p className="section-content">{item.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default AgbComponent;
