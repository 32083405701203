import React from 'react'
import CreateTicket from './createticket'
import TicketResponses from './ticketresponse'
import UserTickets from './userticket'
import Layout from '../../../components/layout'


const Indexticket = () => {
  return (
    <Layout>
      <CreateTicket />
      <UserTickets />
      <TicketResponses />
    </Layout>
  )
}

export default Indexticket