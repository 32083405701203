import React from 'react';
import './Hero.css';
import HZ from "./../../../images/hz04.png";

const CustomComponent = () => {
  return (
    <div className="skewed-background">
      <div className="content-wrapper">
        <div className="left-section">
          <h1 className="title">Memoreyess</h1>
          <p className="text">
            Unsere Event-Management-Plattform ermöglicht es Ihnen, Veranstaltungen zu erstellen,
            Teilnehmer durch ein QR-Code Bilder hochzuladen und Bilder sicher zu verwalten.
          </p>
          <div className="button-group">
            <button className="action-button">Button 1</button>
            <button className="action-button">Button 2</button>
          </div>
        </div>
        <div className="right-section">
          <img className="image" src={HZ} alt="Beispielbild" />
        </div>
      </div>
    </div>
  );
};

export default CustomComponent;
