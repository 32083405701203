// redux/slices/userRoleSlice.js
import { createSlice } from "@reduxjs/toolkit";

const userRoleFromLocalStorage = () => {
  const userRole = localStorage.getItem("userRole");
  return userRole || null;
};

const initialRoleState = {
  userRole: userRoleFromLocalStorage(),
};

export const roleSlice = createSlice({
  name: "role",
  initialState: initialRoleState,
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
  },
});

export const { setUserRole } = roleSlice.actions;


export const roleReducer = roleSlice.reducer;
