import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import QRCode from "qrcode.react";
import Layout from "../../../components/layout";
import { toast } from "react-toastify"; 

const EventManagement = () => {
  const { userId } = useSelector((state) => state.user);
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [eventActivated, setEventActivated] = useState(false);
  const qrRef = useRef(null); // Ref for the QRCode canvas

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/geteventsbyuserid/${userId}`
        );
        const fetchedEvents = response.data.data.events;
  
        if (fetchedEvents && fetchedEvents.length > 0) {
          setEvents(fetchedEvents);
        } else {
          toast.error("Keine Events gefunden.");
        }
      } catch (err) {
        toast.error("Fehler beim Abrufen der Events.");
      }
    };
  
    fetchEvents();
  }, [userId]);
  

  const handleEventSelection = (e) => {
    const eventId = parseInt(e.target.value, 10);
    setSelectedEventId(eventId);
  
    setEventActivated(false); // Reset activation state on event change
  };

  const handleActivateEvent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/${selectedEventId}/activate`,
        { user_id: userId }
      );

      if (response.data.success) {
        toast.success("Event erfolgreich aktiviert, Laden Sie den QR-Code herunter.");
        setEventActivated(true);
      } else {
        toast.error(response.data.error || "Fehler beim Aktivieren des Events.");
      }
    } catch (error) {
      toast.error("Serverfehler beim Aktivieren des Events.");
    }
  };

  const handleDownloadQRCode = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream"); // Convert to a downloadable image
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `event-${selectedEventId}-qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // Finde das ausgewählte Event und hole den Key
  const selectedEvent = events.find((event) => event.event_id === selectedEventId);

  // Erstelle eine URL, die sowohl die event_id als auch den key enthält
  const eventUrl = selectedEventId && selectedEvent?.key
    ? `${process.env.REACT_APP_BASE_URL}/event/${selectedEventId}?key=${selectedEvent.key}`
    : "";

  return (
    <Layout>
      <div className="Padding1 Top">
        <div className="CardContainerTop">
          <div className="CardContainer">
            <div className="CardContainer21">
              <div>
                <div className="CardContainerTitle">
                  <h2>Wählen Sie ein Event aus:</h2>
                </div>
                <select
                  className="form-select"
                  value={selectedEventId}
                  onChange={handleEventSelection}
                >
                  <option value="">-- Wählen Sie ein Event --</option>
                  {events.map((event) => (
                    <option className="option" key={event.event_id} value={event.event_id}>
                      {event.title} (ID: {event.event_id} {event.key ? `Key: ${event.key}` : ""})
                    </option>
                  ))}
                </select>
              </div>



              {selectedEventId && (
                <>
                  <button
                    className="btn-gl"
                    type="button"
                    onClick={handleActivateEvent}
                  >
                    Event aktivieren
                  </button>

                  {eventActivated && (
                    <div style={{ marginTop: "20px" }}>
                      <h3>QR-Code für das ausgewählte Event:</h3>
                      <br />
                      <div ref={qrRef}>
                        <QRCode value={eventUrl} />
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <button className="btn-gl" onClick={handleDownloadQRCode}>
                          QR-Code herunterladen
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EventManagement;
